import styles from "components/cards/productDetailCard.module.scss";
import utilStyles from "styles/utils.module.scss";

import classNames from "classnames";
import Image from "next/image";

import * as Sentry from "@sentry/nextjs";
import {captureException, captureMessage} from "@sentry/nextjs";
import ArrowRightIcon from "images/icons/arrow_right_757575_16.svg";
import DownloadIcon from "images/icons/download.svg";
import ShoppingBagIcon from "images/icons/shopping_bags.svg";

import ChartIcon from "images/icons/chart.svg";
import {ExpandedInfo} from "components/FoldableInfo";
import {useContext, useEffect, useMemo, useRef, useState} from "react";
import {
    calculateReviewScore,
    formatDateInSlash,
    formattedPrice,
    isApp,
    isIOS,
    numberWithComma,
    padWithZero,
    postRNMessage,
    swiperCustomAnimation,
    useOutsideAlerter
} from "common/utils";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import ProductScrapContext from "context/ProductScrapContext";
import {Axios} from "api";
import Link from "next/link";
import {getBrandRoute, KAKAO_CLIENT_ID, SITE_URL, specialOffersRoute} from "common/const";
import UserContext from "context/AuthContext";
import {useRouter} from "next/router";
import Script from "next/script";
import BlackCloseIcon from "images/icons/close_191919.svg";
import CrossIcon from "images/icons/X.svg";
import {toast} from "react-toastify";
import {sellerProductContextRef} from "context/SellerProductContext";
import ArrowDownIcon from "images/icons/arrow_down_brand.svg";
import InformationIcon from "images/icons/information.svg";
import DeliveryIcon from "images/icons/delivery_colored.svg";
import DoubleQuoteIcon from "images/icons/double_quote.svg";
import TruncateMarkup from "react-truncate-markup";
import {MyPriceModal} from "components/modal/FeedOptionModal";
import Modal from "components/Modal";
import ContentSwiper from "components/swiper/ContentSwiper";
import {ReviewCard} from "components/sections/product/ProductReviewSection";
import Loading from "components/Loading";
import RelatedProductsSection from "components/sections/product/RelatedProductsSection";
import {Swiper, SwiperSlide} from "swiper/react";
import SwiperCore, {Pagination} from "swiper";
import {REVERSE_MESSAGE_TYPE_SHARE} from "pages/_app";
import ReviewScrapContext, {reviewScrapContextRef} from "context/ReviewScrapContext";
import useTimer from "hooks/useTimer";
import StarRatingSmall from "components/review/StarRatingSmall";
import ProductDetailCouponDownloadModal from "components/modal/ProductDetailCouponDownloadModal";
import GridReviews from "components/layout/palette/GridReviews";
import ProductReviewModal from "components/modal/ProductReviewModal";
import ModalContext from "context/ModalContext";
import ProductDetailNotice from "components/rows/ProductDetailNotice";
import {HashTagSelectorModal} from "components/cards/ProductListCard";
import {ProductOpenSubscriptionButton} from "components/buttons/SubscriptionButton";
import useNotiSubscription from "hooks/useNotiSubscription";
import {hasValidDirectPurchaseInfo} from "common/product";
import useTranslation from "next-translate/useTranslation";
import Trans from "next-translate/Trans";
import {getLocalizationInfoInCSR} from "common/i18n";
import DeliveryContext from "context/DeliveryContext";


SwiperCore.use([Pagination]);



export default function ProductDetailCard (props) {
    const contextRef = useRef(undefined);
    const {
        product, bestReviews, myPriceInfo, isOpenNotiSubscribed,
        relatedProductInfo, openReviewModal, promotionEndsAt, downloadableCouponMolds , refetchProductDetail, locale,
        countryCode
    } = props;

    const setIsSellerShopAllowed = props.setIsSellerShopAllowed || function () {};
    const isLarge = useMediaQuery(`(min-width:${utilStyles.breakpointDesktop})`);
    const isMobile = useMediaQuery(`(max-width:${utilStyles.breakpointMobile})`);
    const [isCouponDownloadModalOpen, setIsCouponDownloadModalOpen] = useState(false);

    const {timeString} = useTimer(promotionEndsAt);
    const reviewScrapContext = useContext(ReviewScrapContext);
    const deliveryContext = useContext(DeliveryContext);

    const {t} = useTranslation('store-products-index');

    useEffect(() => {
        reviewScrapContext.setScrapInfo(bestReviews);
    }, [bestReviews]);

    const userContext = useContext(UserContext);
    const user = userContext.user;
    const scrapContext = useContext(ProductScrapContext);

    const router = useRouter();

    useEffect(() => {
        contextRef.current = scrapContext;
    }, []);

    useEffect(() => {
        contextRef.current = scrapContext;
    }, [scrapContext]);

    useEffect(() => {
        if (user) {
            getProductScrapInfo();
            if (user.is_seller) {
                getShopProductInfo();
            }
        }
    }, [user]);

    const [isShareModalOpen, setIsShareModalOpen] = useState(false);

    const getShopProductInfo = async () => {
        try {
            const res = await Axios.get(`v1/my-shop/products/${product.id}`);
            if (res.status < 400) {
                const data = {};
                data[parseInt(product.id)] = res.data.is_added;
                setIsSellerShopAllowed(res.data.is_allowed);
                sellerProductContextRef.current.updateProductIds(data);
            } else {
            }
        } catch(e) {
            Sentry.captureException(e);
        } finally {
        }
    }

    const getProductScrapInfo = async () => {
        try {
            const res = await Axios.get(`v1/store/scraps/product/`, {params: {product_id: product.id}});
            if (res.status < 400) {
                const data = {};
                data[parseInt(product.id)] = res.data.is_scrapped;
                contextRef.current.updateProductIds(data);
            } else {
                alert('스크랩 정보를 받아오는 데 실패했습니다. 잠시 후 다시 시도해주세요.');
            }
        } catch(e) {
            alert('스크랩 정보를 받아오는 데 실패했습니다. 잠시 후 다시 시도해주세요.');
            Sentry.captureException(e);
        } finally {
        }
    }

    const shareLink = `${SITE_URL}${router.asPath.split('?')[0]}`;

    const shareModalRef = useRef(null);
    useOutsideAlerter(shareModalRef, () => setIsShareModalOpen(false));

    const brandName = product.brand_name;

    const [myPriceInfoExpanded, setMyPriceInfoExpanded] = useState(false);

    const deliveryDelayInfoText = product.delivery_delay_text ? `평균 3일 이내 배송, ${product.delivery_delay_text}` : '평균 3일 이내 배송';

    const [isAlertModalOpen, setIsAlertModalOpen] = useState(false);

    const [openedReview, setOpenedReview] = useState(null);

    const closeModal = () => {
        setOpenedReview(null);
        history.back();
    };

    const onBrandClick = (brand) => {
        typeof mixpanel !== 'undefined' && mixpanel.track('ProductDetailBrandNameClick', {
            brandName: brand.name,
            brandId: brand.id,
            productName: product.name,
            productId: product.id,
        });
    };

    const onReviewCountClick = () => {
        typeof mixpanel !== 'undefined' && mixpanel.track('ProductDetailReviewCountClick', {
            productName: product.name,
            productId: product.id,
        });
        if (product.review_count > 0) {
            if (props.onReviewClick) {
                props.onReviewClick();
            }
        } else {
            openReviewModal();
        }
    };

    const onShareButtonClick = () => {
        typeof mixpanel !== 'undefined' && mixpanel.track('ProductDetailShareButtonClick', {
            productName: product.name,
            productId: product.id,
        });

        if (isApp && !isIOS()) {
            postRNMessage({
                type: REVERSE_MESSAGE_TYPE_SHARE,
                data: {
                    message: shareLink, url: shareLink,
                },
            });
        } else {
            if (navigator.share) {
                navigator.share({
                    title: `[${brandName}] ${product.name}`,
                    url: shareLink,
                });
            } else {
                setIsShareModalOpen(true);
            }
        }
    };

    const onKakaoShareButtonClick = () => {
        typeof mixpanel !== 'undefined' && mixpanel.track('ProductDetailKakaoShareButtonClick', {
            productName: product.name,
            productId: product.id,
        });
        const commerce = {
            productName: `[${brandName}] ${product.name}`,
            regularPrice: product.price,
            discountRate: product.discount_rate,
            // discountPrice: product.discounted_price === product.price ? undefined : product.discounted_price,
        };

        if (product.price !== product.discounted_price) {
            commerce['discountPrice'] = product.discounted_price;
        }

        try {
            Kakao.Share.sendDefault({
                objectType: 'commerce',
                content: {
                    title: product.comments[0] || product.name,
                    imageUrl: product.image_url,
                    link: {
                        mobileWebUrl: shareLink,
                        webUrl: shareLink,
                    },
                },
                commerce: commerce,
                buttons: [
                    {
                        title: '바로가기',
                        link: {
                            mobileWebUrl: shareLink,
                            webUrl: shareLink,
                        },
                    },
                ],
            });
        } catch (e) {
            Sentry.captureException(e);
        }
    };

    const onClipboardCopyButtonClick = () => {
        typeof mixpanel !== 'undefined' && mixpanel.track('ProductDetailClipboardCopyButtonClick', {
            productName: product.name,
            productId: product.id,
        });
        toast.info('링크가 복사되었습니다.');
        setIsShareModalOpen(false);
    };

    const onSlideChange = (swiper) => {
        typeof mixpanel !== 'undefined' && mixpanel.track('ProductDetailImageSlideChange', {
            productName: product.name,
            productId: product.id,
            activeIndex: swiper.activeIndex,
        });
    };

    const onInformationModalOpen = (e) => {
        typeof mixpanel !== 'undefined' && mixpanel.track('ProductDetailInformationModalOpen', );
        e.preventDefault();
        e.stopPropagation();
        setIsAlertModalOpen(val => !val);
    };

    const onInformationModalClose = () => {
        typeof mixpanel !== 'undefined' && mixpanel.track('ProductDetailInformationModalClose', );
        setIsAlertModalOpen(false);
    };

    const isOpened = useMemo(() => {
        if (product && product.opens_at_timestamp) {
            return product.opens_at_timestamp < Date.now() / 1000;
        } else {
            return true;
        }
    }, [product]);

    const productCountryCode = useMemo(() => {
        return product?.country_code;
    }, [product?.country_code]);

    const optionId = props.optionId;
    const option = product.options?.find(option => option.id === optionId);
    const name = option ? `${product.name} ${option.name}` : product.name;

    const reviewCount = product.review_count + product.mall_review_count;

    const onSellerShopToggle = (isAdded) => {
        typeof mixpanel !== 'undefined' && mixpanel.track(
            'ProductDetailSellerShopButtonClick',
            {
                productId: product.id,
                productName: product.name,
                isAdded,
            }
        );
    };

    const handlePromotionShowMoreClick = () => {
        typeof mixpanel !== 'undefined' && mixpanel.track(
            'ProductDetailPromotionExpiresAtShowMoreClick',
        );
    };

    const handleCouponDownloadButtonClick = () => {
        typeof mixpanel !== 'undefined' && mixpanel.track(
            'ProductDetailCouponDownloadModalOpen',
        );
        setIsCouponDownloadModalOpen(true);
    };

    const handleCouponDownloadModalClose = () => {
        setIsCouponDownloadModalOpen(false);
    };

    const onDownloadSuccess = async () => {
        typeof mixpanel !== 'undefined' && mixpanel.track(
            'ProductDetailCouponDownloadSuccess',
        );
        await refetchProductDetail();
        toast.info('쿠폰이 발급되었습니다.');
    };

    const showMyPrice = user && myPriceInfo && myPriceInfo.final_price !== undefined && myPriceInfo.final_price !== null && product.discounted_price >= myPriceInfo.final_price && countryCode === 'KR'

    const listenToPopstate = () => {
        setOpenedReview(null);
    };

    useEffect(() => {
        window.addEventListener("popstate", listenToPopstate);
        return () => {
            window.removeEventListener("popstate", listenToPopstate);
        };
    }, []);

    const [isAdminHashModalOpen, setIsAdminHashModalOpen] = useState(false);

    const handleReviewClick = (review) => {
        typeof mixpanel !== 'undefined' && mixpanel.track(
            'ProductDetailBestReviewClick',
            {
                reviewId: review.id,
                productId: product.id,
                productName: product.name,
            }
        );
        window.history.pushState({isReviewModalOpen: true}, null, `${window.location.pathname}`);
        setOpenedReview(review);
    };

    const handleShowMoreClick = () => {
        typeof mixpanel !== 'undefined' && mixpanel.track(
            'ProductDetailBestReviewShowMoreClick',
        );
        window.history.pushState({isReviewModalOpen: true}, null, `${window.location.pathname}`);
        setOpenedReview('list');
    };

    const {openAppInstallModal, openCouponModalByIdentifier, couponModalInfo, receivedCouponMoldIdsRef} = useContext(ModalContext);

    const pushModalInfo = (
        couponModalInfo ?
            couponModalInfo.modal_list.filter(e => e.identifier && e.identifier.includes('app_install_modal_point'))[0] :
            null
    );

    const isDirectPurchase = product.is_direct_web_purchase_product || hasValidDirectPurchaseInfo(product.id);

    const pushModalOpenable = !isDirectPurchase && pushModalInfo && !(pushModalInfo.coupon_molds.length > 0 && pushModalInfo.coupon_molds.filter(
        e => e.is_duplication_allowed || !receivedCouponMoldIdsRef.current.includes(e.id)
    ).length === 0);

    const handleDiscountPriceClick = () => {
        typeof mixpanel !== 'undefined' && mixpanel.track(
            'ProductDetailDiscountPriceClick',
        );
        if (myPriceInfo?.is_app_install_coupon) {
            if (isApp()) {
                openCouponModalByIdentifier('app_install_modal_point');
            } else {
                openAppInstallModal();
            }
        }
    };

    const handleColorOptionClick = (option) => {
        if (!!targetColorOption && targetColorOption.id === option.id) {
            router.replace({
                query: Object.assign({}, router.query, {tc_option_id: ''}),
            }, undefined, {shallow: true});
        } else {
            router.replace({
                query: Object.assign({}, router.query, {tc_option_id: option.id}),
            }, undefined, {shallow: true});
        }
    }

    const targetColorOption = useMemo(() => {
        if (!product) {
            return null;
        }
        return product.options?.find(option => option.id.toString() === router.query?.tc_option_id);
    }, [product, router.query?.tc_option_id]);

    const isFromAdmin = useMemo(() => {
        if (typeof window === 'undefined') {
            return false;
        }
        return localStorage.getItem('is_from_admin') === 'true';
    }, []);

    const optionsWithColorCode = useMemo(() => {
        if (!product || !product.options) {
            return [];
        }
        return product.options.filter(option => !!option.color_code && !!option.color_code.match(/#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})/));
    }, [product]);

    const imageSwiperRef = useRef(null);

    useEffect(() => {
        if (!imageSwiperRef.current) {
            return;
        }
        const swiper = imageSwiperRef.current?.swiper;
        if (swiper) {
            swiper.slideTo(0, 0);
        }
    }, [targetColorOption?.id, imageSwiperRef.current]);

    const notiKey = `product_open@${product.id}`;
    const {notiTypeAndKeyToIsSubscribedAndPushOn, toggleSubscription, overrideIsSubscribed} = useNotiSubscription(
        {[notiKey]: isOpenNotiSubscribed}
    );
    const isSubscribed = notiTypeAndKeyToIsSubscribedAndPushOn[notiKey];

    const handleOpenNotiToggle = () => {
        typeof mixpanel !== 'undefined' && mixpanel.track(
            'ProductOpenNotiToggle',
            {productId: product.id, productName: product.name, isSubscribed: !isSubscribed}
        );
        toggleSubscription(notiKey);
    };

    const openDate = product.opens_at_timestamp ? new Date(product.opens_at_timestamp * 1000) : null;
    const openDateStr = product.opens_at_timestamp ? formatDateInSlash(product.opens_at_timestamp, true) : null;

    const isNotOpened = openDate && openDate > new Date();

    const deliveryChargeText = useMemo(() => {
        if (countryCode === 'KR')  {
            return t('ProductDetailCard.freeShipping');
        }
        const deliveryFeeList = deliveryContext?.priceSortedDeliveryFee || [];

        if (deliveryFeeList.length === 0) {
            return t('ProductDetailCard.freeShipping');
        }
        return t(
            'ProductDetailCard.productDeliveryMessage',
            {
                priceText:
                    `\$${formattedPrice(deliveryFeeList[deliveryFeeList.length - 1][0] + 1, product.currency)}`
            }
        )
    }, [deliveryContext?.priceSortedDeliveryFee]);

    return (
        <>
            <Script src="https://developers.kakao.com/sdk/js/kakao.js" onLoad={() => {
                Kakao.init(KAKAO_CLIENT_ID);
                Kakao.isInitialized();
            }} strategy="afterInteractive" />
            {
                openedReview &&
                <ProductReviewModal
                    reviews={bestReviews}
                    selectedReview={openedReview}
                    closeModal={closeModal}
                />
            }
            {
                isCouponDownloadModalOpen &&
                <ProductDetailCouponDownloadModal
                    couponMolds={downloadableCouponMolds}
                    handleClose={handleCouponDownloadModalClose}
                    onDownloadSuccess={onDownloadSuccess}
                />
            }
            <div className={classNames(utilStyles.fullWidth, utilStyles.flexRow, styles.container)}>
                {
                    isFromAdmin && isAdminHashModalOpen && !!product &&
                    <HashTagSelectorModal product={product} close={() => setIsAdminHashModalOpen(false)} isOpen={isAdminHashModalOpen} />
                }

                <div className={styles.imageContainer}>
                    {
                        isNotOpened &&
                        <div className={styles.openContainer}>
                            <div className={styles.openWrapper}>
                                <span>{openDateStr}<br/>{t('ProductDetailCard.openScheduled')}</span>
                            </div>
                            {
                                !(Boolean(countryCode) && countryCode !== 'KR') &&
                                <ProductOpenSubscriptionButton isSubscribed={isSubscribed} onClick={handleOpenNotiToggle}/>
                            }
                        </div>
                    }
                    <Swiper
                        ref={imageSwiperRef}
                        // loop={product.image_urls.length > 1}
                        onSetTranslate={swiperCustomAnimation}
                        spaceBetween={0}
                        slidesPerView={1}
                        threshold={30}
                        pagination={{
                            clickable: true,
                            horizontalClass: styles.productImageSwiperHorizontalClass,
                            bulletClass: styles.productImageSwiperBulletClass,
                            bulletActiveClass: styles.productImageSwiperBulletActiveClass,
                        }}
                        onSlideChange={onSlideChange}
                    >
                    {
                        (!!targetColorOption && targetColorOption.image_urls.length > 0
                            ?
                            targetColorOption.image_urls
                            :
                            product.image_urls
                        ).map((imageUrl, idx) => {
                            return (
                                <SwiperSlide key={imageUrl}>
                                    <div className={styles.imageWrapper}>
                                        <Image
                                            priority="eager"
                                            unoptimized
                                            src={imageUrl}
                                            alt={`${product.name}_${idx}`}
                                            layout="fill"
                                            objectFit="cover"
                                        />
                                        {isNotOpened && <div className={styles.imageOverlay} />}
                                    </div>
                                </SwiperSlide>
                            );
                        })
                    }
                    </Swiper>
                    {
                        isFromAdmin &&
                        <div onClick={(e) => {
                            setIsAdminHashModalOpen(true);
                            e.stopPropagation();
                            e.preventDefault();
                        }} className={styles.adminHashButton}>
                            태그 등록
                        </div>
                    }
                </div>
                {
                    timeString !== null &&
                    <Link href={specialOffersRoute}>
                        <a className={styles.promotionEndsAtTextWrapper} onClick={handlePromotionShowMoreClick}>
                            <div className={styles.iconPlaceholder} />
                            <div className={styles.promotionEndsAtTextContainer}>
                                <div className={styles.promotionEndsAtText}>
                                    <ShoppingBagIcon/>
                                    <span>{t('ProductDetailCard.specialPrice')}</span>
                                    <span className={styles.promotionEndsAtTime}>{timeString}</span>
                                    <span>남음</span>
                                </div>
                            </div>
                            <ArrowRightIcon viewBox="0 0 16 16" className={styles.arrowRightIcon} />
                        </a>
                    </Link>
                }
                {
                    optionsWithColorCode.length > 0 &&
                    <div className={styles.colorCodePickerContainer}>
                        <div className={styles.colorCodePickerDescription}>
                            {
                                targetColorOption ?
                                    targetColorOption.name
                                    :
                                    t('ProductDetailCard.selectColor')
                            }

                        </div>
                        <Swiper
                            spaceBetween={4}
                            slidesPerView={'auto'}
                            slidesOffsetBefore={15}
                            slidesOffsetAfter={15}
                            observer={true}
                            init={true}
                            threshold={5}
                            observeParents={true}
                            watchOverflow={false}
                        >
                            {
                                optionsWithColorCode.map((option, idx) => {
                                    const isActive = targetColorOption?.id === option.id;
                                    return (
                                        <SwiperSlide key={`colorpicker_${option.id}`} className={styles.colorPickerSlide}>
                                            <div className={styles.colorPickerSlideContainer} onClick={() => {
                                                handleColorOptionClick(option);
                                            }}>
                                                <div className={styles.colorPickerSlideSubcontainer} style={{
                                                    backgroundColor: isActive ? 'white' : option.color_code,
                                                    borderColor: isActive ? 'black' : 'white', borderWidth: 1, borderStyle: 'solid',
                                                }}>
                                                    <div className={styles.colorPickerSlideContent} style={{backgroundColor: option.color_code}} />
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                    );
                                })
                            }
                        </Swiper>
                    </div>
                }
                <div className={classNames(utilStyles.fullFlex, styles.infoContainer)}>
                    <div className={utilStyles.newSidePadding}>
                        <div className={styles.brand}>
                        {
                            product.ownership_brands && product.ownership_brands.map((x, i) => {
                                return <div className={styles.brandWrapper} key={`ownership-brand-${i}`}>
                                    <Link href={getBrandRoute(x.id)}>
                                        <a onClick={() => onBrandClick(x)}>{x.name}</a>
                                    </Link>
                                    {
                                        i !== product.ownership_brands.length - 1 &&
                                        <CrossIcon className={styles.crossIcon} />
                                    }
                                </div>
                            })
                        }
                        </div>
                        <div className={classNames(utilStyles.flexRow, utilStyles.alignItemsCenter, utilStyles.justifyContentSpaceBetween, styles.titleContainer)}>
                            <span className={styles.title}>{name}</span>
                        </div>
                        {
                            <div className={styles.reviewContainer} onClick={onReviewCountClick}>
                                <StarRatingSmall
                                    rate={calculateReviewScore(product.review_score, product.review_count, product.mall_review_score, product.mall_review_count)}
                                    noParenthesis gap={4} fontSize={14} color={utilStyles.brandColorText} brandStar
                                />
                                <span className={classNames(styles.reviewCount, product.review_count === 0 && styles.writeReviewText)}>
                                {
                                    product.review_count > 0
                                        ? t('ProductDetailCard.reviewCount', {count: numberWithComma(product.review_count + product.mall_review_count)})
                                        : t('ProductDetailCard.writeReview')
                                }
                            </span>
                                {
                                    product.review_count === 0 && countryCode === 'KR' &&
                                    <div className={styles.firstReviewEventContainer}>
                                        <span>{t('ProductDetailCard.firstReviewBonus')}</span>
                                    </div>
                                }
                            </div>
                        }
                        <div className={styles.priceInfoContainer}>
                            <div className={styles.priceInfoWrapper}>
                                {
                                    !!product.discount_rate &&
                                    <div className={styles.originalPriceContainer}>
                                        <span className={classNames(styles.discountRate)}>
                                            {product.discount_rate}%
                                        </span>
                                        <span className={styles.originalPrice}>{formattedPrice(product.price, product.currency)}</span>
                                    </div>
                                }
                                <div className={styles.priceContainer}
                                     style={{marginTop: !!product.discount_rate ? 6 : 20}}>
                                    <span className={styles.price}>{formattedPrice(product.discounted_price, product.currency)}</span>
                                    <span className={styles.won}>{t(`common:currency.${product.currency}`)}</span>
                                </div>
                                {
                                    myPriceInfo?.coupon_discount > 0 &&
                                    <div className={styles.couponDiscountPriceContainer} onClick={handleDiscountPriceClick}>
                                        <div className={styles.couponDiscountPriceWrapper}>
                                            <span className={styles.couponDiscountPrice}>{formattedPrice(product.discounted_price - (myPriceInfo ? myPriceInfo.coupon_discount : 0), product.currency)}</span>
                                            <span className={styles.couponDiscountWon}>{t(`common:currency.${product.currency}`)}</span>
                                        </div>
                                        <div className={styles.couponDiscountTextWrapper}>
                                            <span className={styles.couponDiscountText}>
                                                {myPriceInfo?.is_app_install_coupon ? t('ProductDetailCard.appFirstPurchasePrice') : t('ProductDetailCard.couponPrice')}
                                            </span>
                                        </div>
                                    </div>
                                }
                            </div>
                            {
                               !isDirectPurchase && downloadableCouponMolds.length > 0 &&
                                <div className={styles.downloadButton} onClick={handleCouponDownloadButtonClick}>
                                    <span className={styles.downloadButtonText}>{t('ProductDetailCard.getCoupon')}</span>
                                    <DownloadIcon className={styles.rightArrow} />
                                </div>
                            }
                        </div>
                        {
                            showMyPrice &&
                            <div className={styles.myPriceInfoContainer}
                                 onClick={() => {
                                     typeof mixpanel !== 'undefined' && mixpanel.track('ProductDetailMyPriceInfoClick', {product, isExpanded: myPriceInfoExpanded});
                                     setMyPriceInfoExpanded(val => !val);
                                 }}
                            >
                                <div className={styles.myPriceInfoTotalPriceContainer}>
                                    <div className={classNames(
                                        utilStyles.flexRow, utilStyles.alignItemsCenter, utilStyles.noLineHeight, utilStyles.relative,
                                    )}>
                                        <span className={styles.myPriceInfoTotalPriceKey}>{t('ProductDetailCard.myLowestPrice')}</span>
                                        <InformationIcon viewBox="0 0 16 16" width={14} height={14} onClick={onInformationModalOpen} />
                                        {
                                            isAlertModalOpen &&
                                            <MyPriceModal close={onInformationModalClose} />
                                        }
                                    </div>
                                    <div className={classNames(
                                        utilStyles.flexRow, utilStyles.alignItemsCenter, utilStyles.noLineHeight,
                                    )}>
                                        <span className={styles.myPriceInfoTotalPriceValue}>{formattedPrice(myPriceInfo.final_price, product.currency)}{t(`common:currency.${product.currency}`)}</span>
                                        <ArrowDownIcon
                                            viewBox="0 0 20 20" className={styles.myPriceInfoTotalPriceArrowIcon}
                                            style={{transform: myPriceInfoExpanded ? 'rotate(180deg)' : undefined}}
                                        />
                                    </div>
                                </div>
                                {
                                    myPriceInfoExpanded &&
                                    <div className={styles.myPriceDiscountContainer}>
                                        <div className={
                                            classNames(utilStyles.flexRow, utilStyles.justifyContentSpaceBetween, utilStyles.alignItemsCenter)
                                        }>
                                            <span className={styles.myPriceDiscountKey}>{t('ProductDetailCard.couponDiscount')}</span>
                                            <span className={styles.myPriceDiscountValue}>-{formattedPrice(myPriceInfo.coupon_discount, product.currency)}{t(`common:currency.${product.currency}`)}</span>
                                        </div>
                                        <div style={{height: 8}}/>
                                        <div className={
                                            classNames(utilStyles.flexRow, utilStyles.justifyContentSpaceBetween, utilStyles.alignItemsCenter)
                                        }>
                                            <span className={styles.myPriceDiscountKey}>{t('ProductDetailCard.availablePoints')}</span>
                                            <span className={styles.myPriceDiscountValue}>-{formattedPrice(myPriceInfo.point_discount, product.currency)}{t(`common:currency.${product.currency}`)}</span>
                                        </div>
                                    </div>
                                }
                            </div>
                        }
                    </div>

                        <div className={utilStyles.newSidePadding}>
                            {
                                productCountryCode === 'KR' &&
                                <ProductDetailNotice showAppInstallNotice={pushModalOpenable} showMyPrice={showMyPrice}/>
                            }
                            <div className={styles.infoSection}>
                                <div className={styles.infoRow}>
                                    <span className={classNames(styles.infoTitle, locale !== 'ko' && styles.infoGlobalTitle)}>{t('ProductDetailCard.shippingFee')}</span>
                                    <span className={classNames(styles.infoBody, styles.infoBodyEmphasis)}>
                                        {deliveryChargeText}
                                    </span>
                                </div>
                                <div className={styles.infoRow}>
                                    <span className={classNames(styles.infoTitle, locale !== 'ko' && styles.infoGlobalTitle)}>{t('ProductDetailCard.points')}</span>
                                    <span className={styles.infoBody}>
                                    <Trans
                                        i18nKey="store-products-index:ProductDetailCard.maxPointsEarning"
                                        components={[<span className={styles.infoBodyEmphasis}/>]}
                                        values={{point: Math.ceil(product.discounted_price / 100.0)}}
                                    />
                                </span>
                                </div>
                            </div>
                        </div>
                    {
                        bestReviews && bestReviews.filter(e => e.images.length > 0).length > 0 &&
                        <>
                            <div className={styles.border}/>
                            <div className={styles.bestReviewText}>
                                {t('ProductDetailCard.bestReview')}
                            </div>
                            <div className={styles.bestReviewSection}>
                                <GridReviews
                                    reviews={bestReviews}
                                    handleReviewClick={handleReviewClick}
                                    handleShowMoreClick={handleShowMoreClick}
                                />
                            </div>
                        </>
                    }
                    {
                        <>
                            {
                                relatedProductInfo && relatedProductInfo.related_products && relatedProductInfo.related_products.length > 0 &&
                                <>
                                    <div className={utilStyles.mobileBorder}/>
                                    <div>
                                        <RelatedProductsSection title={
                                            relatedProductInfo.related_products_title ? relatedProductInfo.related_products_title :
                                                (
                                                    isOpened ? t('ProductDetailCard.otherCustomerCombo') : t('ProductDetailCard.goodWithProduct')
                                                )
                                        } products={relatedProductInfo.related_products}/>
                                    </div>
                                </>
                            }
                            {(isMobile && product?.comments?.length > 0) && <div className={utilStyles.mobileBorder}/>}
                            {
                                (product?.comments?.length > 0) &&
                                <div className={utilStyles.sidePadding}>
                                    <div className={styles.hemekoChartTitleContainer}>
                                        <ChartIcon className={styles.chartIcon} viewBox="0 0 24 24"/>
                                        <span className={styles.hemekoChartTitle}>{t('ProductDetailCard.hemekoComment')}</span>
                                    </div>
                                    <ExpandedInfo
                                        title={t('ProductDetailCard.hemekoComment')} contents={product.comments || []}
                                    />
                                    {isLarge && <div className={styles.border}/>}
                                </div>
                            }
                        </>
                    }
                </div>
            </div>
        </>
    )
}
