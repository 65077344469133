import {newPostRoute, paletteRoute} from "common/const";
import Link from "next/link";
import classNames from "classnames";
import styles from "components/buttons/AddPostButton.module.scss";
import WhitePlusIcon from "images/icons/plus_white.svg";
import {useRouter} from "next/router";
import {useContext, useEffect, useState} from "react";
import ScrollContext from "context/ScrollContext";
import useTranslation from "next-translate/useTranslation";


export const AddPostButton = () => {
    const [isAddButtonExtended, setIsAddButtonExtended] = useState(true);

    const router = useRouter();
    const scrollContext = useContext(ScrollContext);
    const {t} = useTranslation('components-buttons-AddPostButton');
    useEffect(() => {
        const scrollCallback = (accUp, accDown) => {
            if (accUp >= 90) {
                setIsAddButtonExtended(false);
            }
        }
        scrollContext.addCallback(scrollCallback);
    }, []);

    useEffect(() => {
        router.events.on('routeChangeComplete', (e) => {
            if (e === paletteRoute) {
                setTimeout(() => {
                    setIsAddButtonExtended(true);
                    scrollContext.reset();
                }, 500);
            }
        })
    }, []);


    if (
        // router.pathname === paletteRoute
        // ||
        router.pathname === '/palette/double'
        ||
        router.pathname === '/palette/single'
        ||
        router.pathname === '/palette/[pid]'
        ||
        (router.pathname === '/s/[ccode]' && router.query.tab === 'palette')
        ||
        (router.pathname === '/palette/creator/[ccode]' && router.query.tab === 'palette')
    ) {
        return <Link href={newPostRoute}>
            <a className={classNames(styles.addButtonContainer, isAddButtonExtended ? styles.addButtonContainerExtended : undefined)}
               onClick={() => {
                   typeof mixpanel !== 'undefined' && mixpanel.track('UploadPalettePostButton');
               }}
            >
                <WhitePlusIcon />
                {
                    isAddButtonExtended &&
                    <span className={styles.addButtonText}>{t('write')}</span>
                }
            </a>
        </Link>
    } else {
        return <></>
    }
}
