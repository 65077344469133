import Modal from "components/Modal";
import styles from "components/modal/ProductDetailCouponDownloadModal.module.scss";
import FullButton from "components/buttons/FullButton";
import DownloadableCouponCard from "components/cards/coupon/DownloadableCouponCard";
import {Axios} from "api";
import {redirectToLogin} from "common/redirect";
import {captureException, captureMessage} from "@sentry/nextjs";
import {toast} from "react-toastify";
import {useState, useContext, useMemo, useEffect} from "react";
import {useRouter} from "next/router";
import {customAlert, customConfirm, isAndroid, isApp, isIOS} from "common/utils";
import UserContext from "context/AuthContext";
import {redirectToApp} from "common/redirect";
import useTranslation from 'next-translate/useTranslation';


const ProductDetailCouponDownloadModal = (props) => {
    const [isLoading, setIsLoading] = useState(false);
    const [downloadedCouponIds, setDownloadedCouponIds] = useState([]);
    const { couponMolds, handleClose, onDownloadSuccess } = props;
    const { t } = useTranslation('components-modal-ProductDetailCouponDownloadModal');

    const handleModalClose = () => {
        handleClose();
    };

    const router = useRouter();
    const {user, couponInfo} = useContext(UserContext);

    const sortedCouponMolds = useMemo(() => {
        const downloadableCouponMolds = couponMolds.filter(couponMold => couponMold.is_obtainable);
        const unDownloadableCouponMolds = couponMolds.filter(couponMold => !couponMold.is_obtainable);
        return [...downloadableCouponMolds, ...unDownloadableCouponMolds];
    }, [couponMolds]);

    const handleDownloadAll = async () => {
        typeof mixpanel !== 'undefined' && mixpanel.track(
            'ProductDetailCouponDownloadModalDownloadAllClick'
        );
        if (isLoading) {
            return;
        }

        const obtainableCouponMolds = couponMolds.filter(couponMold => couponMold.is_obtainable);
        if (obtainableCouponMolds.length === 0) {
            toast.info(t('ProductDetailCouponDownloadModal.allDownloaded'));
            return;
        }
        const appOnlyCouponMolds = obtainableCouponMolds.filter(couponMold => couponMold.is_app_only);
        if (appOnlyCouponMolds.length > 0 && !isApp()) {
            if (isIOS() || isAndroid()) {
                customConfirm(
                    t('ProductDetailCouponDownloadModal.appOnlyConfirm'),
                    t('ProductDetailCouponDownloadModal.confirm'),
                    t('ProductDetailCouponDownloadModal.cancel'),
                    () => {
                        redirectToApp();
                    },
                    () => {}
                );
            } else {
                customAlert(
                    t('ProductDetailCouponDownloadModal.appOnlyAlert'),
                    t('ProductDetailCouponDownloadModal.confirm'),
                    () => {}
                );
            }
            return;
        }

        if (!user) {
            redirectToLogin(router, true);
            return;
        }
        setIsLoading(true);

        const couponMoldKeys = obtainableCouponMolds.map(couponMold => couponMold.mold_key);
        const couponMoldIds = obtainableCouponMolds.map(couponMold => couponMold.id);
        try {
            let res;
            if (couponMoldKeys.length === 1) {
                const data = {
                    mold_key: couponMoldKeys[0],
                }
                res = await Axios.post('v1/coupons/download', data);
            } else {
                const data = {
                    mold_keys: couponMoldKeys,
                }
                res = await Axios.post('v1/coupons/download-bulk', data);
            }

            if (res.status < 400) {
                onDownloadSuccess();
                setDownloadedCouponIds(prev => {
                    return [...prev, ...couponMoldIds];
                });
            } else if (res.status === 401) {
                redirectToLogin(router, true);
            } else {
                captureMessage(JSON.stringify(res.data));
                toast.info(res.data.display_message || t('ProductDetailCouponDownloadModal.downloadError'));
            }
        } catch (e) {
            captureException(e);
            toast.info(t('ProductDetailCouponDownloadModal.downloadError'));
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (couponInfo) {
            setDownloadedCouponIds(couponInfo?.coupons?.map(coupon => coupon.coupon_mold_id));
        }
    }, [couponInfo])

    return (
        <Modal showOverflow={false} isOpen={true} bottom={true} width="100%" maxHeight={600}
               close={handleModalClose}
        >
            <div className={styles.root}>
                <div className={styles.title}>
                    <span>{t('ProductDetailCouponDownloadModal.title')}</span>
                </div>
                <div className={styles.couponList}>
                    {
                        sortedCouponMolds.map((coupon, index) => {
                            return <DownloadableCouponCard
                                key={index} couponMold={coupon} onSuccess={onDownloadSuccess}
                                hasDownloaded={downloadedCouponIds.includes(coupon?.id)}
                                setDownloadedCoupons={setDownloadedCouponIds}
                            />
                        })
                    }
                </div>
                <div className={styles.bottomButtonContainer}>
                    <FullButton
                        height={48} fontSize={15}
                        white
                        title={t('ProductDetailCouponDownloadModal.close')}
                        onClick={handleModalClose}
                    />
                    <FullButton
                        height={48} fontSize={15}
                        title={user ? t('ProductDetailCouponDownloadModal.downloadAll') : t('ProductDetailCouponDownloadModal.quickDownload')}
                        onClick={handleDownloadAll}
                    />
                </div>
            </div>
        </Modal>
    )
};

export default ProductDetailCouponDownloadModal;
