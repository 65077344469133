import styles from "components/cards/checkoutCouponCard.module.scss";
import utilStyles from "styles/utils.module.scss";
import RadioInactiveIcon from "images/icons/radio_inactive.svg";
import RadioActiveIcon from "images/icons/radio_active.svg";
import {formatDate, formattedPrice} from "common/utils";
import classNames from "classnames";
import useTimer from "hooks/useTimer";
import {useMemo} from "react";
import useTranslation from "next-translate/useTranslation";
import Trans from 'next-translate/Trans';


export const COUPON_TYPE_PERCENTAGE = 'PTG';
export const COUPON_TYPE_FIXED = 'FIX';

export default function CheckoutCouponCard(props) {
    const {coupon, selectedCoupons, isLoading, withBorder, unselectable, relative, expired, showTotal} = props;
    const onSelect = props.onSelect || function () {
    };

    const {t} = useTranslation('components-cards-CheckoutCouponCard');

    const currency = useMemo(() => {
        return coupon?.currency;
    }, [coupon?.currency])

    const currencyText = useMemo(() => {
        return t(`common:currency.${coupon?.currency}`);
    }, [coupon?.currency]);

    const amount = relative ? coupon.amount : coupon.discount;
    const unit = relative ? (coupon.type === COUPON_TYPE_PERCENTAGE ? '%' : currencyText) : currencyText;

    const {timeString, seconds} = useTimer(coupon.usable_after);

    const condition = useMemo(() => {
        if (coupon.min_amount && coupon.max_amount) {
            return t('CheckoutCouponCard.minPurchaseWithMax', {
                minAmount: formattedPrice(coupon.min_amount, currency),
                maxAmount: formattedPrice(coupon.max_amount, currency),
                currency: currencyText
            });
        }
        if (coupon.min_amount) {
            return t('CheckoutCouponCard.minPurchase', {
                minAmount: formattedPrice(coupon.min_amount, currency),
                currency: currencyText
            });
        }

        if (coupon.max_amount) {
            return t('CheckoutCouponCard.maxDiscount', {
                maxAmount: formattedPrice(coupon.max_amount, currency),
                currency: currencyText
            });
        }
        return null;
    }, [coupon, currency, currencyText]);

    const couponDiscountValueTitle = () => {
        if (coupon.type === COUPON_TYPE_FIXED) {
            return formattedPrice(amount, currency);
        }
        return amount;
    }

    return (
        <div className={classNames(styles.couponContainer, withBorder ? styles.borderedContainer : undefined)}
             onClick={() => {
                 !isLoading && !unselectable && onSelect(coupon)
             }}>
            <div>
                <div className={classNames(utilStyles.flexRow, utilStyles.alignItemsCenter, (
                    coupon.is_app_only || coupon.is_gift_only || coupon.is_double_discount || (!unselectable && timeString) || !!coupon.is_over_people_count
                ) ? styles.tagsContainer : undefined)}>
                    {
                        coupon.is_app_only &&
                        <div className={styles.appOnlyLabel}>
                            {t('CheckoutCouponCard.appOnly')}
                        </div>
                    }
                    {
                        coupon.is_gift_only &&
                        <div className={styles.giftOnlyLabel}>
                            {t('CheckoutCouponCard.giftOnly')}
                        </div>
                    }
                    {
                        coupon.is_double_discount &&
                        <div className={styles.doubleDiscountLabel}>
                            {t('CheckoutCouponCard.doubleDiscount')}
                        </div>
                    }
                    {
                        !unselectable && timeString &&
                        <div className={styles.timerLabel}>
                            {t('CheckoutCouponCard.usableAfter', { time: timeString })}
                        </div>
                    }
                    {
                        !!coupon.is_over_people_count &&
                        <div className={styles.fifoOver}>
                            {t('CheckoutCouponCard.couponDepleted')}
                        </div>
                    }
                </div>
                <div className={styles.discountAmountWrapper}>
                    <span className={classNames(styles.discount, expired ? styles.expiredDiscount : undefined)}>
                        {couponDiscountValueTitle()}{unit}
                        {
                            (coupon.type === COUPON_TYPE_PERCENTAGE && showTotal && coupon?.discount > 0) &&
                            <span className={styles.totalDiscount}> ({formattedPrice(coupon.discount, currency)}{currencyText} {t('CheckoutCouponCard.discount')})</span>
                        }
                    </span>
                </div>
                <span
                    className={classNames(styles.name, expired ? styles.expiredName : undefined)}>{coupon.name}</span>
                {
                    condition &&
                    <span className={classNames(styles.conditionText, expired ? styles.expiredName : undefined)}>
                        {condition}
                    </span>
                }
                <span className={classNames(styles.period, expired ? styles.expiredPeriod : undefined)}>
                    {
                        !!coupon.used_at ? (
                            <span>{t('CheckoutCouponCard.usedDate')} : {formatDate(coupon.used_at)}
                                {
                                    !!coupon.order_number &&
                                    <>
                                        <br/>
                                        <div style={{marginTop: 4}}>{t('CheckoutCouponCard.orderNumber')}: {coupon.order_number}</div>
                                    </>
                                }
                            </span>
                        ) : (
                            Math.floor(new Date() / 1000) > coupon.expires_at
                                ? t('CheckoutCouponCard.expired')
                                : `${formatDate(coupon.created_at)}-${formatDate(coupon.expires_at)}`
                        )
                    }
                </span>
            </div>
            {
                !unselectable &&
                (
                    selectedCoupons.filter(e => e.id === coupon.id).length > 0
                        ?
                        <RadioActiveIcon viewBox="0 0 24 24" className={styles.radioButton}/>
                        :
                        <RadioInactiveIcon viewBox="0 0 24 24" className={styles.radioButton}/>
                )
            }
        </div>
    )
}
