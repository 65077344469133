import styles from "components/sections/TallMainBanner.module.scss";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import classNames from "classnames";
import {useContext, useEffect, useMemo, useRef} from "react";
import {Swiper, SwiperSlide} from "swiper/react";
import SwiperCore, {Autoplay, Navigation, Pagination} from "swiper";
import 'swiper/css';
import "swiper/css/pagination";
import "swiper/css/navigation";
import Link from "next/link";
import {brandRoute, eventsRoute} from "common/const";
import {getLocalStorageCountryCode, isApp, swiperCustomAnimation} from "common/utils";
import ModalContext from "context/ModalContext";
import {useRouter} from "next/router";
import LabelText from "components/textbox/LabelText";


SwiperCore.use([Navigation, Pagination, Autoplay]);

const BannerContent = ({banner}) => {
    const labelDecorations = banner.decorations?.filter(decoration => decoration.type === 'label');

    return (
        <div>
            <img className={classNames(banner.type === 'vertical' ? styles.verticalImage : styles.horizontalImage)}
                 src={banner.mobile_image_url} alt={banner.text || "banner"}/>
            {
                !banner.hide_overlay &&
                <div className={styles.overlay}/>
            }
            {
                (banner.text || banner.sub_text || (labelDecorations && labelDecorations.length > 0)) &&
                <div className={styles.tallMainBannerBottomContainer}>
                    {
                        labelDecorations && labelDecorations.length > 0 &&
                        <div className={styles.labelContainer}>
                            {
                                labelDecorations.map((decoration, idx) => {
                                    return (
                                        <LabelText key={idx} fontSize={12} backgroundColor={decoration.color}
                                                    fontWeight={600}
                                            content={decoration.content} />
                                    )
                                })
                            }
                        </div>
                    }
                    {
                        (banner.text || banner.sub_text) &&
                        <div className={styles.tallMainBannerTextContainer}>
                            <span className={styles.tallMainBannerTitle}>
                                {
                                    banner.text?.split('\n').map((line, idx) => {
                                        return (
                                            <span key={`text-${idx}`}>{line}<br/></span>
                                        )
                                    })
                                }
                            </span>
                            <span className={styles.tallMainBannerSubText}>
                                {
                                    banner.sub_text?.split('\n').map((line, idx) => {
                                        return (
                                            <span key={`sub_text-${idx}`}>{line}<br/></span>
                                        )
                                    })
                                }
                            </span>
                        </div>
                    }
                </div>
            }
        </div>
    )
}


export default function TallMainBanner({banners, isLoading, hidePaginator, small}) {

    const prevRef = useRef(null);
    const nextRef = useRef(null);


    const swiperRef = useRef();
    const swipedRef = useRef(0);

    const {openChuseokModal, openCouponModalByIdentifier} = useContext(ModalContext);

    const router = useRouter();
    const bannerType = banners?.[0]?.type;

    const onClick = (banner, idx) => {
        console.log('banner', banner);
        typeof mixpanel !== 'undefined' && mixpanel.track(
            'BannerClick', {
                bannerIndex: idx,
                adminTitle: banner.admin_title,
                image_url: banner.image_url,
                link: banner.link
            }
        );

        if (banner.link) {
            router.push(banner.link);
        }
        if (banner.modal_identifier && !isApp()) {
            openCouponModalByIdentifier(banner.modal_identifier);
        }
        if (banner.app_modal_identifier && isApp()) {
            openCouponModalByIdentifier(banner.app_modal_identifier);
        }
    };

    const onShowMoreClick = () => {
        typeof mixpanel !== 'undefined' && mixpanel.track('BannerShowMoreClick');
    }

    useEffect(() => {
        if (!swiperRef.current) return;
        const bannerShowMoreButton = document.getElementById('fractionContainer');
        if (!bannerShowMoreButton) return;
        bannerShowMoreButton.addEventListener('click', onShowMoreClick);
        return () => {
            bannerShowMoreButton.removeEventListener('click', onShowMoreClick);
        }
    }, [swiperRef.current]);

    const onTouchEnd = (swiper) => {
        swipedRef.current = Date.now();
    };

    const onSlideChange = (swiper) => {
        if (Date.now() - swipedRef.current < 1000) {
            typeof mixpanel !== 'undefined' && mixpanel.track(
                'MainPageMainBannerSwipe',
                {
                    activeIndex: swiper.activeIndex,
                    nextBannerName: banners[swiper.activeIndex]?.admin_title,
                }
            );
        }
    }

    const timerRef = useRef(null);

    const swiperAutoPlay = () => {
        if (banners && banners.length <= 1) return;
        if (timerRef.current) clearTimeout(timerRef.current);
        timerRef.current = setTimeout(() => {
            if (swiperRef.current?.swiper) {
                swiperRef.current.swiper.slideNext();
            }
            swiperAutoPlay();
        }, 5000);
    }

    const stopAutoPlay = () => {
        if (timerRef.current) clearTimeout(timerRef.current);
    }

    const resumeAutoPlay = () => {
        swiperAutoPlay();
    }

    useEffect(() => {
        swiperAutoPlay();
    }, []);

    const countryCode = getLocalStorageCountryCode() ?? 'KR';

    const moreButtonRouteLink = useMemo(() => {
        if (countryCode === 'KR') {
            return eventsRoute;
        } else {
            return brandRoute;
        }
    }, [countryCode])

    useEffect(() => {
        if (!swiperRef.current) return;
        swiperRef.current.addEventListener('mouseenter', stopAutoPlay);
        swiperRef.current.addEventListener('mouseleave', resumeAutoPlay);
        return () => {
            swiperRef.current.removeEventListener('mouseenter', stopAutoPlay);
            swiperRef.current.removeEventListener('mouseleave', resumeAutoPlay);
        }
    }, [swiperRef.current]);

    return (
        <div
            className={classNames(styles.root, small ? styles.small : undefined)}>
            <div className={bannerType === 'vertical' ? styles.verticalSwiperWrapper : styles.swiperWrapper}>
                {
                    banners && banners.length > 0 ?
                        <Swiper
                            className={styles.swiper}
                            ref={swiperRef}
                            loop={banners && (banners.length > 1)}
                            onSetTranslate={swiperCustomAnimation}

                            // autoplay={{
                            //     delay: 3500,
                            //     disableOnInteraction: false,
                            //     pauseOnMouseEnter: true,
                            //     stopOnLastSlide: false,
                            // }}
                            onInit={(swiper) => {
                                swiper.params.navigation.prevEl = prevRef.current;
                                swiper.params.navigation.nextEl = nextRef.current;
                                swiper.navigation.init();
                                swiper.navigation.update();
                            }}
                            onSlideChangeTransitionEnd={(swiper) => {
                                swiperAutoPlay();
                            }}
                            spaceBetween={0}
                            slidesPerView={1}
                            threshold={20}
                            pagination={{
                                type: 'fraction',
                                // renderFraction: (currentClass, totalClass) => {
                                //     return
                                // }
                                // renderBullet: (index, className) => {
                                //     return <span>{index}</span>
                                // }
                                horizontalClass: hidePaginator ? styles.hiddenSwiper : styles.swiperHorizontal,
                                currentClass: hidePaginator ? styles.hiddenSwiper : styles.swiperCurrent,
                                renderFraction: (currentClass, totalClass) => {
                                    return `
                                    <a href="${moreButtonRouteLink}" id="fractionContainer">
                                    <div class="${styles.paginationIndicatorWrapper}">
                                        <div class="${styles.paginationIndicator}">
                                            <span class="${currentClass}"></span>/<span class="${totalClass}"></span>
                                        </div>
                                    </div>
                                    </a>
                                `;
                                }
                                // bulletClass: styles.swiperBullet,
                                // bulletActiveClass: styles.swiperBulletActive,
                            }}
                            onSlideChange={onSlideChange}
                            onTouchEnd={onTouchEnd}
                        >
                            {
                                banners.map((banner, idx) => {
                                    return (
                                        <SwiperSlide key={idx}>
                                            {
                                                banner.link ?
                                                    <Link href={banner.link || ''}>
                                                        <a className={styles.bannerItem} onClick={() => onClick(banner, idx)}>
                                                            <BannerContent banner={banner} />
                                                        </a>
                                                    </Link> :
                                                    <div className={styles.bannerItem} onClick={() => onClick(banner, idx)}>
                                                        <BannerContent banner={banner} />
                                                    </div>
                                            }
                                        </SwiperSlide>
                                    )
                                })
                            }
                            {/*<div className={styles.buttonControllerContainer}>*/}
                            {/*    <div ref={prevRef} className={styles.swiperPrevButton} style={{display: isMobile ? 'none' : undefined}}>*/}
                            {/*        <ArrowLeftCircle viewBox="0 0 24 24" />*/}
                            {/*    </div>*/}
                            {/*    <div ref={nextRef} className={styles.swiperNextButton} style={{display: isMobile ? 'none' : undefined}}>*/}
                            {/*        <ArrowRightCircle viewBox="0 0 24 24" />*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                        </Swiper>
                        :
                        <div className={classNames(styles.root, small ? styles.small : undefined, styles.placeholder)} />
                }
            </div>
        </div>
    )
}
