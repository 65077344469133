import axios from "axios";
import {BASE_URL, CDN_BASE_URL, SSR_BASE_URL, US_BASE_URL, US_CDN_BASE_URL, US_SSR_BASE_URL} from "common/const";
import axiosBetterStacktrace from "axios-better-stacktrace";
import {captureException} from "@sentry/nextjs";
import {isApp} from "common/utils";
import {buildKeyGenerator, buildMemoryStorage, setupCache} from "axios-cache-interceptor";
import {v4} from "uuid";
import {getLocalizationInfoInCSR} from "common/i18n";


export let _Axios = null;

export const getLocalizedAxios = () => {
    if (_Axios) {
        return _Axios;
    }
    let baseURL = BASE_URL;
    const {countryCode} = getLocalizationInfoInCSR();
    // console.log('getLocalizedAxios countryCode', countryCode);
    if (countryCode === 'US') {
        baseURL = US_BASE_URL;
    }
    _Axios = axios.create({
        baseURL: `${baseURL}/api`,
        timeout: 10000,
        withCredentials: true,
        validateStatus: status => status < 500,
        headers: {
            "content-type": 'application/json',
            'is-app': isApp(),
        },
    });
    _Axios.interceptors.request.use(
        (config) => {
            try {
                if (typeof document !== 'undefined' && document.referrer) {
                    config.cache.ttl = 0;
                    config.headers['referrer-site'] = document.referrer;
                }
                return config;
            } catch (err) {
                captureException(err);
            }
            config.cache.ttl = 0;
            return config;
        },
        (error) => {
            // 요청 에러 직전 호출됩니다.
            return Promise.reject(error);
        }
    );
    axiosBetterStacktrace(_Axios);
    setupCache(_Axios, {
        storage: buildMemoryStorage(false, false, 100),
        generateKey: buildKeyGenerator((request) => {
            return {
                method: request.method,
                url: request.url,
                params: request.params,
                custom: typeof window === 'undefined' ? v4() : (window.history.state?.key ? window.history.state.key : v4()),
            }
        }),
        cacheTakeover: false,
    });
    return _Axios;
};

export const Axios = new Proxy({}, {
    get: (target, prop) => {
        const _axios = getLocalizedAxios();
        return _axios[prop];
    }
});

const getDefaultCacheOption = () => {
    const _defaultCacheOption = typeof window === 'undefined' ? false : Object.assign({}, Axios.defaults.cache);
    if (_defaultCacheOption) {
        _defaultCacheOption.ttl = 60 * 60 * 60 * 1000;
    }
    return _defaultCacheOption;
};

export const defaultCacheOption = new Proxy({}, {
    get: (target, prop) => {
        const cacheOption = getDefaultCacheOption();
        return cacheOption[prop];
    }
});

let countryCodeToSSRAxios = {};

// Use different server for SSR
export const getLocalizedSSRAxios = (countryCode) => {
    if (countryCode in countryCodeToSSRAxios) {
        return countryCodeToSSRAxios[countryCode];
    }
    console.log('getLocalizedSSRAxios', countryCode);
    let baseURL = SSR_BASE_URL;
    if (countryCode === 'US') {
        baseURL = US_SSR_BASE_URL;
    }
    const _axios = axios.create({
        baseURL: `${baseURL}/api`,
        timeout: 10000,
        withCredentials: true,
        validateStatus: status => status < 500,
        headers: {
            "content-type": 'application/json',
        },
    });
    axiosBetterStacktrace(_axios);
    countryCodeToSSRAxios[countryCode] = _axios;
    return _axios;
};

let _CDNAxios = null;

export const getLocalizedCDNAxios = () => {
    if (_CDNAxios) {
        return _CDNAxios;
    }
    let baseURL = CDN_BASE_URL;
    const {countryCode} = getLocalizationInfoInCSR();
    if (countryCode === 'US') {
        baseURL = US_CDN_BASE_URL;
    }
    // console.log('getLocalizedCDNAxios', countryCode, baseURL);
    _CDNAxios = axios.create({
        baseURL: `${baseURL}/api`,
        timeout: 10000,
        withCredentials: true,
        validateStatus: status => status < 500,
        headers: {
            "content-type": 'application/json',
        },
    });
    axiosBetterStacktrace(_CDNAxios);
    return _CDNAxios;
};

export const CDNAxios = new Proxy({}, {
    get: (target, prop) => {
        const _axios = getLocalizedCDNAxios();
        return _axios[prop];
    }
});


export * from 'api/products';
