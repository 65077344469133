import styles from "components/cards/VerticalProductListCard.module.scss";
import utilStyles from "styles/utils.module.scss";
import classNames from "classnames";
import ScrapIcon from "images/icons/new_heart_empty.svg";
import ScrapIconActive from "images/icons/new_heart_fill.svg";
import {
    calculateReviewScore,
    formatDateInSlash,
    formattedPrice,
    getDaysLeft, getLocalStorageCountryCode,
    highlightMatchedText,
    leadingZeros,
    numberWithComma
} from "common/utils";
import Link from "next/link";
import {getCreatorProductRoute, getReservationProductRoute, productsRoute} from "common/const";
import {removeLocalReferralInfo, setLocalReferralInfo} from "common/referral";
import ProductScrapButton from "components/buttons/ProductScrapButton";
import TruncateMarkup from "react-truncate-markup";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {useContext, useEffect, useMemo, useRef, useState} from "react";
import FullButton from "components/buttons/FullButton";
import ModalContext from "context/ModalContext";
import OptionSelectorModal from "components/modal/OptionSelectorModal";
import {toast} from "react-toastify";
import ToastContext from "context/ToastContext";
import addOptionCountsToPouch from "api/addToPouch";
import PouchContext from "context/PouchContext";
import UserContext from "context/AuthContext";
import StarRatingSmall from "components/review/StarRatingSmall";
import RollingImage from "components/images/RollingImage";
import useTimer from "hooks/useTimer";
import Modal from "components/Modal";
import BlackCloseIcon from "images/icons/close_191919.svg";
import {Axios} from "api";
import LoadingDot from "components/LoadingDot";
import {InfiniteLoader, List} from "react-virtualized";
import parse from 'html-react-parser';
import useTranslation from "next-translate/useTranslation";


const LOCAL_STORAGE_KEY_RECENT_HASH_TAGS = 'admin_recent_hash_tags';


export const HashTagSelectorModal = ({product, close, isOpen}) => {
    const [isHashTagLoading, setIsHashTagLoading] = useState(true);
    const [tags, setTags] = useState([]);

    const [inputText, setInputText] = useState('');
    const [query, setQuery] = useState('');

    const fetchProductHashTags = async () => {
        setIsHashTagLoading(true);
        try {
            const res = await Axios.get(`v1/store/products/${product.id}/hash-tags/`);
            if (res.status < 400) {
                setTags(res.data.tags);
            } else {
                toast.info(t('HashTagSelectorModal.loadTagError'));
            }
        } catch (e) {
            toast.info(t('HashTagSelectorModal.loadTagError'));
        } finally {
            setIsHashTagLoading(false);
        }
    };

    const fetchCache = useRef({});

    const [pseudoState, setPseudoState] = useState(0);

    const fetchHashTags = async () => {
        if (fetchCache.current[cursorRef.current]) return;
        fetchCache.current[cursorRef.current] = true;
        try {
            const config = {
                params: {
                    size: 10,
                    cursor: cursorRef.current,
                }
            };
            if (query) {
                config.params.query = query;
            }
            const res = await Axios.get(`v1/managed-tags/`, config);
            if (res.status < 400) {
                cursorRef.current = res.data.cursor;
                tagListRef.current = [...tagListRef.current, ...res.data.tags];
                setPseudoState(val => val + 1);
            } else {
                toast.info('일시적인 오류로 태그를 불러오지 못했습니다.');
            }
        } catch (e) {
            toast.info('일시적인 오류로 태그를 불러오지 못했습니다.');
        }
    }

    useEffect(() => {
        fetchCache.current = {};
        cursorRef.current = undefined;
        tagListRef.current = [];
        setPseudoState(val => val + 1);
        fetchHashTags();
    }, [query]);

    const debounceRef = useRef(null);
    const debounceTextChange = () => {
        setInputText(inputRef.current.value);
        if (debounceRef.current) clearTimeout(debounceRef.current);
        debounceRef.current = setTimeout(() => {
            setQuery(inputRef.current.value);
        }, 200);
    };

    const inputRef = useRef(null);
    const [isTransactionLoading, setIsTransactionLoading] = useState(false);

    useEffect(() => {
        fetchProductHashTags();
    }, []);

    const tagListRef = useRef([]);
    const cursorRef = useRef(undefined);

    const queryExists = useMemo(() => {
        if (!query) return false;
        return tagListRef.current.filter(e => e.name === query).length > 0;
    }, [query]);
    const remoteRowCount = cursorRef.current !== null ? tagListRef.current.length + 1 : tagListRef.current.length;

    const onHashTagDelete = async (tagName) => {
        try {
            setIsTransactionLoading(true);
            const res = await Axios.delete(
                `v1/store/products/${product.id}/hash-tags/`,
                {params: {tag: tagName}},
            );
            if (res.status < 400) {
                setTags(res.data.tags);
            } else {
                toast.info(t('HashTagSelectorModal.tagDeletionError'));
            }
        } catch (e) {
            toast.info(t('HashTagSelectorModal.tagDeletionError'));
        } finally {
            setIsTransactionLoading(false);
        }
    }

    const onHashTagAdd = async (tagName) => {
        try {
            setIsTransactionLoading(true);
            const res = await Axios.post(
                `v1/store/products/${product.id}/hash-tags/`,
                {tag: tagName},
            );
            if (res.status < 400) {
                setTags(res.data.tags);
                const recentTags = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY_RECENT_HASH_TAGS) || '[]');
                if (recentTags.filter(tag => tag === tagName).length === 0) {
                    const newRecentTags = recentTags.concat([tagName]);
                    localStorage.setItem(LOCAL_STORAGE_KEY_RECENT_HASH_TAGS, JSON.stringify(newRecentTags));
                }
            } else {
                toast.info(t('HashTagSelectorModal.tagRegistrationError'));
            }
        } catch (e) {
            toast.info(t('HashTagSelectorModal.tagRegistrationError'));
        } finally {
            setIsTransactionLoading(false);
        }
    }

    const onResultRowClick = async (tagName) => {
        if (tags.filter(tag => tag.name === tagName).length > 0) {
            await onHashTagDelete(tagName);
            return;
        }
        await onHashTagAdd(tagName);
    }

    const rowRenderer = ({key, index, style}) => {
        const hashTag = tagListRef.current[index];
        return (
            <div key={key} style={style}>
                {
                    !hashTag ?
                        <div
                            className={classNames(utilStyles.fullHeight, utilStyles.justifyContentCenter, utilStyles.flexRow)}>
                            <LoadingDot/></div>
                        :
                        <div
                            className={classNames(styles.resultRowContainer, tags.filter(tag => tag.name === hashTag.name).length > 0 ? styles.registeredResultRowContainer : undefined)}
                            onClick={() => onResultRowClick(hashTag.name)}>
                            {hashTag.name}
                        </div>
                }
            </div>
        );
    }

    const recentHashTags = useMemo(() => {
        return JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY_RECENT_HASH_TAGS) || '[]');
    }, []);

    return (
        <Modal isOpen={isOpen} close={close} round>
            <div className={styles.hashTagModalContainer} onClick={e => {
                e.stopPropagation();
                e.preventDefault();
            }}>
                {
                    !!isTransactionLoading &&
                    <div className={styles.overLay}>
                        <LoadingDot/>
                    </div>
                }
                <div className={styles.searchContainer}>
                    <input ref={inputRef} type="text" className={styles.searchInput} value={inputText}
                           onChange={debounceTextChange}/>
                </div>

                {
                    !!recentHashTags && recentHashTags.length > 0 &&
                    <div className={styles.recentTagContainer}>
                        <span className={styles.recentTagTitle}>{t('HashTagSelectorModal.recentTags')}</span>
                        <div className={styles.recentTagsContainer}>
                            {
                                recentHashTags.map((e, index) => {
                                    return (
                                        <div key={`${e}-${index}`} className={styles.recentTag} onClick={() => {
                                            onHashTagAdd(e);
                                        }}>
                                            {e}
                                        </div>
                                    );
                                })
                            }
                        </div>
                    </div>
                }
                <div className={styles.resultContainer} key={query}>
                    {
                        !!tagListRef.current && tagListRef.current.length > 0 ?
                            <InfiniteLoader
                                isRowLoaded={({index}) => !!tagListRef.current[index]}
                                loadMoreRows={({startIndex, stopIndex}) => {
                                    fetchHashTags(query);
                                }}
                                rowCount={remoteRowCount}
                            >
                                {({onRowsRendered, registerChild}) => (
                                    <List
                                        height={400}
                                        onRowsRendered={onRowsRendered}
                                        ref={registerChild}
                                        rowCount={remoteRowCount}
                                        rowHeight={44}
                                        rowRenderer={rowRenderer}
                                        width={340}
                                    />
                                )}
                            </InfiniteLoader>
                            :
                            <div style={{height: 400}}>
                                {
                                    !!query && cursorRef.current === null && tags.filter(e => e.name === query).length === 0 &&
                                    <div style={{height: 44}} className={classNames(styles.resultRowContainer)}
                                         onClick={() => {
                                             onHashTagAdd(query.trim());
                                         }}>
                                        <span className={styles.brandColorText}>{query}</span>&nbsp;{t('HashTagSelectorModal.createAndAddTag')}
                                    </div>
                                }
                            </div>
                    }
                </div>

                <div className={styles.hashTagResult}>
                    {
                        isHashTagLoading ?
                            <div
                                className={classNames(utilStyles.fullWidth, utilStyles.flexRow, utilStyles.alignItemsCenter, utilStyles.justifyContentCenter)}
                                style={{height: 38}}>
                                <LoadingDot width={'100%'}/>
                            </div>
                            :
                            (
                                tags.length === 0 ?
                                    <div style={{height: 38}}/>
                                    :
                                    tags.map(tag => {
                                        return (
                                            <div key={tag.name} className={styles.hashTag}>
                                                {tag.name}
                                                <BlackCloseIcon onClick={() => {
                                                    onHashTagDelete(tag.name);
                                                }} viewBox="0 0 24 24" className={styles.hashTagClose}/>
                                            </div>
                                        )
                                    })
                            )
                    }
                </div>
            </div>
        </Modal>
    );
}


export default function VerticalProductListCard(props) {
    const isMobile = useMediaQuery(`(max-width:${utilStyles.breakpointMobile})`);
    const {
        product, productNameMaxLine, showRank, referralInfo, horizontal,
        extraSmallTitle, creatorCode, showPromotionDaysLeft, hidePriceInfo, imageRatio, miniScrapIcon,
        hideOriginalPrice, hideTags, hasAddToPouchButton, description, className, highlightKeyword, newRank,
        useBestCouponDiscount,
    } = props;
    const {t} = useTranslation('components-cards-VerticalProductListCard');
    const theme = props.theme || 'default';
    const isDefaultTheme = theme === 'default';
    const country_code = getLocalStorageCountryCode()
    const onClick = props.onClick || function () {
    };
    const onCloseOptionSelectorModal = props.onCloseOptionSelectorModal || function () {
    };
    const maxLine = productNameMaxLine || 2;
    const numOptionsDisplay = props.numOptionsDisplay || (isMobile ? 'bottom' : 'right');
    const productOptions = product?.options || [];

    const priceUnit = () => {
        if (product?.currency === 'USD') {
            return <span className={styles.priceUnit}>USD</span>
        }
    }

    const check_sold_out_by_options = () => {
        if (product?.is_sold_out !== undefined) {
            return product.is_sold_out;
        }

        for (const option of productOptions) {
            if (!option.is_sold_out) {
                return false;
            }
        }
        if (productOptions.length === 0) {
            return false;
        }

        return true;
    }

    const isSoldOut = check_sold_out_by_options();

    const onCardClick = () => {
        if (referralInfo) {
            const [referralId, referralPostId] = referralInfo;
            setLocalReferralInfo(product.id, referralId, referralPostId);
        } else {
            removeLocalReferralInfo(product.id);
        }
        onClick();
    }

    const couponInfo = useContext(UserContext).couponInfo;
    const myPriceInfo = product?.coupon_discount_infos || product?.my_price_info;

    const bestCouponDiscount = useMemo(() => {
        if (!useBestCouponDiscount) {
            return myPriceInfo ? myPriceInfo.coupon_discount : 0;
        }

        const couponDiscountInfos = myPriceInfo?.coupon_discount_infos !== undefined
            ? myPriceInfo?.coupon_discount_infos : product?.coupon_discount_infos;

        if (!Boolean(couponInfo.coupons) || !Boolean(couponInfo.downloadable_coupons) || !Boolean(myPriceInfo) || !Boolean(couponDiscountInfos)) {
            return 0;
        }
        const availableCouponMoldIds = [
            ...couponInfo.coupons.map(coupon => coupon.coupon_mold_id),
            ...couponInfo.downloadable_coupons.map(coupon => coupon.id),
        ];

        const couponDiscounts = couponDiscountInfos.filter(
            e => !Boolean(e.expires_at) || e.expires_at > (Date.now() / 1000)
        ).filter(
            e => availableCouponMoldIds.includes(e.coupon_mold_id)
        );

        if (couponDiscounts.length === 0) {
            return 0;
        }

        const initialDiscounts = couponDiscounts?.filter(e => !e.is_double_discount).map(e => e.discount);
        const doubleDiscounts = couponDiscounts?.filter(e => e.is_double_discount).map(e => e.discount);

        let maxInitialCouponDiscount = 0;
        let maxDoubleCouponDiscount = 0;

        if (initialDiscounts?.length > 0) {
            maxInitialCouponDiscount = Math.max(...initialDiscounts);
        }

        if (doubleDiscounts?.length > 0) {
            maxDoubleCouponDiscount = Math.max(...doubleDiscounts);
        }

        return Math.min(maxInitialCouponDiscount + maxDoubleCouponDiscount, product.discounted_price);
    }, [couponInfo, myPriceInfo, useBestCouponDiscount]);

    const calculateDiscountRate = (originalPrice, discountedPrice) => {
        if (originalPrice > 0 && originalPrice > discountedPrice) {
            return Math.floor(100.0 * (originalPrice - discountedPrice) / originalPrice);
        }
        return 0;
    }

    const priceText = () => {
        if (!product.is_entered)
            return t('VerticalProductListCard.notListed');
        return formattedPrice(product.discounted_price - bestCouponDiscount, product.currency);
    }

    const discountRate = () => {
        if (!isCouponDiscountApplied) {
            return product.discount_rate;
        }
        return calculateDiscountRate(product.price, product.discounted_price - bestCouponDiscount);
    }

    const isDiscounted = (product.price !== product.discounted_price) || (bestCouponDiscount > 0);
    const hasSpecialOffer = !!product.has_special_offer;
    const isCouponDiscountApplied = bestCouponDiscount > 0;
    const discountInfoText = isCouponDiscountApplied ? t('VerticalProductListCard.couponPrice') :
        hasSpecialOffer ? t('VerticalProductListCard.hemekoSpecialPrice') : null;

    const productLink = (
        product.override_link ?
            product.override_link
            :
            (
                product.is_shop_reservation ?
                    getReservationProductRoute(product.id) :
                    (!!creatorCode ? `${getCreatorProductRoute(creatorCode, product.id)}` : `${productsRoute}/${product.id}`)
            )
    )

    const promotionDaysLeft = showPromotionDaysLeft && product.promotion_ends_at
        ? getDaysLeft(product.promotion_ends_at)
        : null;

    const {openModal, closeModal} = useContext(ModalContext) || {};
    const toastContext = useContext(ToastContext);
    const pouchContext = useContext(PouchContext);
    const user = useContext(UserContext).user;

    const [isAdminHashModalOpen, setIsAdminHashModalOpen] = useState(false);

    const onAddToPouchSuccessOverride = () => {
        const toastId = toast.info(t('VerticalProductListCard.addToCart'));
        toastContext.registerToast(toastId);
        closeModal();
        onCloseOptionSelectorModal();
    };

    const handleCloseOptionSelectorModal = () => {
        closeModal();
        onCloseOptionSelectorModal();
    };

    const addToPouch = (e) => {
        e.stopPropagation();
        e.preventDefault();
        toastContext.closeToast();
        const options = productOptions;
        if (options.length === 1) {
            const [referralId, referralPostId] = referralInfo || [null, null];
            const pouchOptions = [
                {
                    option_id: parseInt(options[0].id),
                    count: 1,
                    referral_id: referralId,
                    referral_post_id: referralPostId,
                    price: options[0].discounted_price
                }
            ];
            addOptionCountsToPouch(pouchOptions, user != null, onAddToPouchSuccessOverride, pouchContext,
                false, product.id, product.name);
        } else {
            openModal(() => {
                return <OptionSelectorModal
                    product={product} isModalOpen={true}
                    close={handleCloseOptionSelectorModal}
                    buttonText={"장바구니 담기"}
                    onAddToPouchSuccessOverride={onAddToPouchSuccessOverride}
                    isPouchForced
                />
            });
        }
    };

    const {timeString} = useTimer(product.opens_at_timestamp);

    const isFromAdmin = useMemo(() => {
        if (typeof window === 'undefined') {
            return false;
        }
        return localStorage.getItem('is_from_admin') === 'true';
    }, []);

    const formattedTime = product.opens_at_timestamp ? formatDateInSlash(product.opens_at_timestamp, true) : null;


    const {brandName, highlightedName, optionName} = useMemo(() => {
        let highlightedName = highlightKeyword ? highlightMatchedText(product.name, highlightKeyword) : null;
        let isKeywordHighlighted = highlightKeyword ? highlightedName !== product.name : false;
        let optionName = null;
        if (highlightKeyword && !isKeywordHighlighted) {
            for (const option of productOptions) {
                // if (option.is_sold_out) continue;
                if (optionName === null) {
                    const highlightedOptionName = highlightMatchedText(option.name, highlightKeyword);
                    if (highlightedOptionName !== option.name) {
                        optionName = highlightedOptionName;
                    }
                } else {
                    isKeywordHighlighted = true;
                    break;
                }
            }
        }
        let brandName = product?.brand_name || product?.brand?.name;

        if (highlightKeyword && !isKeywordHighlighted) {
            brandName = highlightMatchedText(brandName, highlightKeyword);
        }
        return {
            brandName: parse(brandName),
            highlightedName: highlightedName === null ? null : parse(highlightedName),
            optionName: optionName === null ? null : parse(optionName),
        }
    }, [product]);

    return (
        <div className={styles.root}>
            <Link href={productLink} shallow={false} scroll={false} className={styles[`theme-${theme}`]}>
                <a onClick={onCardClick}
                   className={classNames(utilStyles.cursorPointer, utilStyles.fullHeight, utilStyles.flexCol, utilStyles.justifyContentSpaceBetween, className)}>
                    {
                        !!isFromAdmin && !!product && isAdminHashModalOpen &&
                        <HashTagSelectorModal product={product} isOpen={isAdminHashModalOpen}
                                              close={() => setIsAdminHashModalOpen(false)}/>
                    }
                    <div className={styles.container}>
                        <div className={classNames(utilStyles.flexCol, utilStyles.relative, styles.media)}>
                            <div className={utilStyles.square}
                                 style={{paddingBottom: imageRatio, width: horizontal ? 130 : undefined}}>
                                {
                                    isFromAdmin &&
                                    <div onClick={(e) => {
                                        setIsAdminHashModalOpen(true);
                                        e.stopPropagation();
                                        e.preventDefault();
                                    }} className={styles.adminHashButton}>
                                        {t('VerticalProductListCard.registerTag')}
                                    </div>
                                }
                                {
                                    (isSoldOut && product.is_entered) ?
                                        <div className={styles.overlay}>
                                            <span>SOLD OUT</span>
                                        </div> :
                                        (formattedTime && timeString) &&
                                        <div className={styles.overlay}>
                                            <span>{formattedTime}<br/>{t('VerticalProductListCard.openingSoon')}</span>
                                        </div>
                                }
                                {
                                    showPromotionDaysLeft && product.promotion_ends_at &&
                                    <div className={classNames(styles.tagWrapper, styles.daysLeftTagWrapper)}>
                                    <span className={classNames(styles.tag, styles.daysLeftTag)}>
                                        {
                                            promotionDaysLeft > 0
                                                ? t('VerticalProductListCard.daysLeft', {days: promotionDaysLeft})
                                                : t('VerticalProductListCard.endingToday')
                                        }
                                    </span>
                                    </div>
                                }
                                {
                                    product && product?.thumbnail_urls?.length > 0 &&
                                    <RollingImage prefix={"product_images"} images={product?.thumbnail_urls}/>
                                }
                                {
                                    showRank && newRank &&
                                    <div className={classNames(styles.newRank, product.rank > 3 && styles.lowRankV2)}>
                                        {product.rank}
                                    </div>
                                }
                            </div>
                        </div>
                        <div
                            className={classNames(styles.infoContainer)}>
                            <div className={styles.brandSection}>
                                <span className={styles.brand}>{brandName}</span>
                                {numOptionsDisplay === 'right' && productOptions.length > 1 &&
                                    <span className={styles.colorCount}
                                          style={{fontSize: extraSmallTitle && 11}}>
                                        {t('VerticalProductListCard.options', {count: productOptions.length})}
                                    </span>
                                }
                            </div>
                            {
                                !newRank && showRank && product.rank <= 50 &&
                                <div className={classNames(styles.rank, product.rank > 3 && styles.lowRank)}>
                                    <span>{leadingZeros(product.rank, 2)}</span>
                                </div>
                            }
                            <div>
                                <TruncateMarkup lines={maxLine} ellipsis={<span>...</span>}>
                                    <div className={styles.name} style={{
                                        fontSize: optionName ? 12 : undefined,
                                        marginBottom: optionName ? 2 : undefined
                                    }}>
                                        {highlightKeyword ? highlightedName : product.name}
                                    </div>
                                </TruncateMarkup>
                                {
                                    optionName &&
                                    <TruncateMarkup lines={maxLine} ellipsis={<span>...</span>}>
                                        <div className={styles.optionName} style={{
                                            fontSize: optionName ? 11 : undefined,
                                            marginBottom: undefined
                                        }}>
                                            {optionName}
                                        </div>
                                    </TruncateMarkup>
                                }
                            </div>

                            {
                                !hidePriceInfo &&
                                <div>
                                    <div className={classNames(styles.itemContainer, utilStyles.relative)}>
                                        <div className={styles.itemPriceContainer}>
                                            {isDiscounted && !hideOriginalPrice &&
                                                <div className={styles.originalPriceContainer} style={{
                                                    fontSize: extraSmallTitle ? 11 : undefined,
                                                    marginBottom: undefined
                                                }}>
                                                    {discountInfoText && <span
                                                        className={classNames(styles.discountTag, isCouponDiscountApplied && styles.couponDiscountTag)}>{discountInfoText}</span>}
                                                    <span
                                                        className={styles.originalPrice}>{formattedPrice(product.price, product.currency)}</span>
                                                </div>
                                            }
                                            <div
                                                className={styles.discountedPriceContainer}
                                                style={{fontSize: horizontal ? 16 : undefined}}
                                            >
                                                {
                                                    isDiscounted &&
                                                    <>
                                                        <span className={styles.discount}>{discountRate()}</span>
                                                        <span className={styles.percentage}>%</span>
                                                    </>
                                                }
                                                <span className={styles.price}>{priceText()}</span>
                                                {
                                                    priceUnit()
                                                }
                                            </div>
                                            {
                                                product && !hideTags && (product.review_count > 0 || product.mall_review_count > 0) &&
                                                <div className={styles.reviewInfoContainer}
                                                     style={{marginTop: horizontal ? 12 : undefined}}>
                                                    <StarRatingSmall rate={calculateReviewScore(product.review_score, product.review_count, product.mall_review_score, product.mall_review_count)} noParenthesis
                                                                     gap={4}
                                                                     fontSize={11} size={11}
                                                                     scoreTextClassname={styles.scoreText}
                                                    />
                                                    <TruncateMarkup lines={1} ellipsis={<span>...</span>}>
                                                    <span
                                                        className={styles.reviewCount}>{t('VerticalProductListCard.reviewCount', {count: numberWithComma(product.review_count + product.mall_review_count)})}</span>
                                                    </TruncateMarkup>
                                                </div>
                                            }
                                            {
                                                description &&
                                                <div className={styles.descriptionWrapper}>
                                                    <span>{description}</span>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                        {
                            product.is_entered &&
                            <div
                                className={classNames(styles.likeContainer, miniScrapIcon ? styles.miniScrapIcon : undefined)}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    e.preventDefault();
                                }}>
                                <ProductScrapButton
                                    addPadding={extraSmallTitle}
                                    productId={product.id}
                                    renderScrapped={() => <div className={styles.scrapIconWrapper}>
                                        <ScrapIconActive
                                            viewBox="0 0 24 24"
                                            width={miniScrapIcon ? 20 : extraSmallTitle ? 20 : 24}
                                            height={miniScrapIcon ? 20 : extraSmallTitle ? 20 : 24}
                                            className={styles.likeIcon}
                                        />
                                        <span
                                        className={styles.scrapFillCountText}>{numberWithComma(product.total_scrap_count + 1)}</span>
                                    </div>
                                    }
                                    renderUnscrapped={() =>
                                        <div className={styles.scrapIconWrapper}>
                                            <ScrapIcon
                                                viewBox="0 0 24 24"
                                                width={miniScrapIcon ? 20 : extraSmallTitle ? 20 : 24}
                                                height={miniScrapIcon ? 20 : extraSmallTitle ? 20 : 24}
                                                className={styles.likeIcon}
                                            />
                                            <span
                                                className={styles.scrapEmptyCountText}>{numberWithComma(product.total_scrap_count)}</span>
                                        </div>
                                    }
                                />
                                {/*<span*/}
                                {/*    className={styles.scrapFillCountText}>{product.total_scrap_count + 1}</span>*/}
                            </div>
                        }
                    </div>
                    {
                        hasAddToPouchButton &&
                        <div className={styles.addToPouchButton}>
                            <FullButton
                                title={t('VerticalProductListCard.addToCartButton')} white height={27} fontSize={13} fontWeight={600}
                                onClick={addToPouch} theme={theme}
                            />
                        </div>
                    }
                </a>
            </Link>
        </div>
            )
            }
