import styles from "components/sections/curation/CurationSection.module.scss";
import {useMemo, useState} from "react";
import ArrowRightIcon from "images/icons/arrow_right_black_16.svg";
import classNames from "classnames";
import {ProductCurationSection} from "components/sections/curation/ProductCurationSection";
import {BulletsCurationSection} from "components/sections/curation/BulletsCurationSection";
import {BrandCurationSection} from "components/sections/curation/BrandCurationSection";
import {EventCurationSection} from "components/sections/curation/EventCurationSection";
import FullButton from "components/buttons/FullButton";
import {useRouter} from "next/router";
import {PostCurationSection} from "components/sections/curation/PostCurationSection";
import Link from "next/link";
import parse from 'html-react-parser';
import {shuffleCurationItems} from "components/sections/mainpage/MainPageSectionList";

export const CurationSection = (props) => {
    const {curation, location, isFoldable, isFoldedFromStart, isTitleHidden, isAddToPouchVisible, onPouchAdd, useBestCouponDiscount, theme} = props;
    const router = useRouter();

    const selectedTheme = theme || 'default';

    const [isFolded, setIsFolded] = useState(isFoldable ? isFoldedFromStart : false);

    const hasTitleLink = curation && curation.link && !curation.button_text && !curation.link_text;

    const handleTitleClick = () => {
        typeof mixpanel !== 'undefined' && mixpanel.track(
            'CurationSectionTitleClick',
            {
                location,
                curationTitle: curation.title,
                curationDescription: curation.description,
            }
        );
        if (isFoldable) {
            setIsFolded(prev => !prev);
        }
        if (hasTitleLink) {
            router.push(curation.link);
        }
    };

    const content = useMemo(() => {
        if (!Boolean(curation)) {
            return <div/>;
        }

        if (curation?.is_shuffled) {
            shuffleCurationItems(curation)
        }
        if (curation.category === 'product') {
            return <ProductCurationSection {...props} />;
        }
        if (curation.category === 'post') {
            return <PostCurationSection {...props} />;
        }

        if (curation.category === 'bullets') {
            return <BulletsCurationSection {...props} />;
        }

        if (curation.category === 'brand') {
            return <BrandCurationSection {...props} />;
        }

        if (curation.category === 'events') {
            return <EventCurationSection {...props} />;
        }

        return <div/>;

    }, [curation]);

    const handleButtonClick = () => {
        typeof mixpanel !== 'undefined' && mixpanel.track(
            'CurationSectionButtonClick',
            {
                location,
                curationTitle: curation.title,
                curationDescription: curation.description,
            }
        );
    };

    if (!Boolean(curation)) {
        return <div/>;
    }

    return (
        <div className={styles[`theme-${selectedTheme}`]}>
            <div className={styles.sectionContainer}>
                {
                    (!isTitleHidden && !curation?.is_hide_title) &&
                    <div className={classNames(styles.sectionTitleWrapper, isFolded && styles.foldedTitle)} onClick={handleTitleClick}>
                        <div>
                            <div className={styles.sectionTitle}>{parse(curation.title)}</div>
                            {
                                curation.description &&
                                <div className={styles.sectionDescription}>{curation.description}</div>
                            }
                        </div>
                        <div>
                            {
                                (isFoldable || hasTitleLink) &&
                                <ArrowRightIcon
                                    className={classNames(styles.arrowRightIcon, isFoldable && styles.foldIcon, isFolded && styles.unfoldIcon)}
                                    viewBox="0 0 16 16" height={20} width={20}
                                />
                            }
                        </div>
                        {
                            curation.link_text &&
                            <div className={styles.linkText}>
                                {curation.link_text}
                            </div>
                        }
                    </div>
                }
                {
                    !isFolded &&
                    <>
                        {content}
                        {
                            curation.button_text && curation.link &&
                            <div className={curation.display_type === 'BANNER'? styles.bannerButtonContainer : styles.buttonContainer}>
                                <Link href={curation.link}>
                                    <a>
                                        <FullButton height={44} fontSize={16} white onClick={handleButtonClick}
                                                    sidePadding={12} theme={selectedTheme}>
                                            <div className={styles.buttonWrapper}>
                                                <div className={styles.buttonIcon}/>
                                                <span>{curation.button_text}</span>
                                                <ArrowRightIcon viewBox="0 0 16 16" className={styles.buttonIcon}/>
                                            </div>
                                        </FullButton>
                                    </a>
                                </Link>
                            </div>
                        }
                    </>
                }
            </div>
        </div>
    );
};
