import styles from "components/layout/store/products/HorizontalProducts.module.scss";
import ProductListCard from "components/cards/ProductListCard";
import ShowMoreIcon from "images/icons/right_arrow_with_circle.svg";
import 'overlayscrollbars/overlayscrollbars.css';
import {OverlayScrollbarsComponent} from "overlayscrollbars-react";


const HorizontalProducts = (props) => {
    const {
        products, onProductClick, hidePriceInfo, width, imageRatio, productNameMaxLine,
        miniScrapIcon, onShowMoreClick, onPouchAdd, isAddToPouchVisible, highlightKeyword, showRank,
        useBestCouponDiscount, theme,
    } = props;

    const showMoreHeight = imageRatio ? width * parseFloat(imageRatio.replace('%', '')) / 100 : width;
    return (
            <div className={styles.productsWrapper} {...{'body-scroll-lock-ignore': "true"}}>
                <div className={styles.horizontalScrollPaddingLeft} />
                {
                    products.map((e, index) => {
                        return (
                            <div className={styles.cardWrapper} key={index} style={{flexBasis: width}}>
                                <ProductListCard
                                    onCloseOptionSelectorModal={() => onPouchAdd(e)}
                                    product={e} showPromotionDaysLeft
                                    onClick={() => onProductClick(e, index)}
                                    hidePriceInfo={hidePriceInfo}
                                    imageRatio={imageRatio}
                                    productNameMaxLine={productNameMaxLine}
                                    miniScrapIcon={miniScrapIcon}
                                    hasAddToPouchButton={isAddToPouchVisible}
                                    highlightKeyword={highlightKeyword}
                                    showRank={showRank}
                                    newRank={showRank}
                                    useBestCouponDiscount={useBestCouponDiscount}
                                    theme={theme}
                                />
                            </div>
                        )
                    })
                }
                {
                    onShowMoreClick &&
                    <div className={styles.showMoreContainer} onClick={onShowMoreClick} style={{height: showMoreHeight}}>
                        <ShowMoreIcon/>
                        <span className={styles.showMoreText}>더보기</span>
                    </div>
                }
                <div className={styles.horizontalScrollPaddingRight} />
            </div>
        // </OverlayScrollbarsComponent>
    )
};

export default HorizontalProducts;
