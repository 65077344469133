import {useEffect, useRef, useState} from "react";
import {secondToVerboseText} from "common/timeConverter";
import {COUNTRY_CODE} from "common/const";

const useTimer = (targetTimestamp) => {
    const [seconds, setSeconds] = useState(0);
    const timerRef = useRef();
    const countryCode = COUNTRY_CODE;

    useEffect(() => {
        const seconds = targetTimestamp ? targetTimestamp - (Date.now() / 1000) : 0;
        setSeconds(seconds) ;
        if (seconds > 0) {
            timerRef.current = setInterval(() => {
                const remainSeconds = targetTimestamp ? targetTimestamp - (Date.now() / 1000) : 0;
                if (remainSeconds <= 0) {
                    setSeconds(remainSeconds);
                    clearInterval(timerRef.current);
                } else {
                    setSeconds(remainSeconds);
                }
            }, 1000);
        }

        return () => {
            if (timerRef.current) {
                clearInterval(timerRef.current);
            }
        }
    }, [targetTimestamp]);

    return {seconds, ...secondToVerboseText(seconds, countryCode)};
};

export default useTimer;
