import HorizontalProducts from "components/layout/store/products/HorizontalProducts";
import GridProducts from "components/layout/store/products/GridProducts";
import {isProductSoldOut, shuffle} from "common/utils";
import styles from "components/sections/curation/CurationSection.module.scss";
import {useMemo} from "react";
import RotateProducts from "components/layout/store/products/RotateProducts";
import {BannerProductCurationSection} from "components/sections/curation/BannerProductCurationSection";


export const ProductCurationSection = (props) => {
    const {curation, curationIdx, onPouchAdd, location, isAddToPouchVisible, useBestCouponDiscount, theme} = props;

    const products = useMemo(() => {
        let items = curation.item_list.filter((product) => {
            return !isProductSoldOut(product)
        });
        if (curation.max_item_count) {
            items = items.slice(0, curation.max_item_count);
        }
        return items;
    }, [curation]);

    const handleProductClick = props.onProductClick || (
        (product, productIndex) => {
            typeof mixpanel !== 'undefined' && mixpanel.track(
                'ProductCurationSectionProductClick',
                {
                    location,
                    curationTitle: curation.title,
                    curationDescription: curation.description,
                    productId: product.id,
                    productName: product.name,
                    productIndex,
                    curationIdx
                }
            );
        }
    );

    if (curation.display_type === 'HORIZONTAL') {
        if (curation.is_rank_shown) {
            for (let i = 0; i < products.length; i++) {
                products[i].rank = i + 1;
            }
        }
        return (
            <HorizontalProducts
                onPouchAdd={onPouchAdd}
                products={products}
                onProductClick={handleProductClick}
                isAddToPouchVisible={isAddToPouchVisible}
                showRank={curation.is_rank_shown}
                useBestCouponDiscount={useBestCouponDiscount}
                theme={theme}
            />
        )
    } else if (curation.display_type.includes('COLUMN')) {
        const columnCountStr = curation.display_type.split('_')[0];
        const columnCount = columnCountStr === 'ONE' ? 1 : columnCountStr === 'TWO' ? 2 : 3;
        const textPaddingType = columnCount === 3 ? 'small' : 'medium';
        return (
            <div className={styles.gridContainer}>
                <GridProducts
                    onPouchAdd={onPouchAdd}
                    products={products}
                    onProductClick={handleProductClick}
                    columnCount={columnCount}
                    isAddToPouchVisible={isAddToPouchVisible}
                    showPromotionDaysLeft
                    useBestCouponDiscount={useBestCouponDiscount}
                    theme={theme}
                    textPaddingType={textPaddingType}
                />
            </div>
        )
    } else if (curation.display_type === 'BANNER') {
        return <BannerProductCurationSection {...props} />
    } else if (curation.display_type === 'SUSHI') {
        return (
            <div>
                <RotateProducts
                    onPouchAdd={onPouchAdd}
                    products={products}
                    onProductClick={handleProductClick}
                    showRank={curation.is_rank_shown}
                    useBestCouponDiscount={useBestCouponDiscount}
                    productIdToDescription={curation?.product_id_to_description}
                    theme={theme}/>
            </div>
        )
    } else {
        return <div/>;
    }
}
