import {
    brandRoute, COUNTRY_CODE,
    isHomeRoute,
    myPageRoute,
    paletteRoute,
    rewardsRoute,
    searchRoute,
    specialOffersRoute,
    storeRoute
} from "common/const";
import {useEffect} from "react";
import {confirmAlert} from "react-confirm-alert";
import styles from "common/utils.module.scss";
import {INPUT_CLASS_NAME} from "components/input/TextInput";
import {toast} from "react-toastify";
import ToastContent from "components/ToastContent";
import {toastContextRef} from "context/ToastContext";

export const numberWithComma = (number) => {
    if (!Number.isInteger(number)) {
        return number;
    }
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const calculateReviewScore = (reviewScore, reviewCount, mallReviewScore, mallReviewCount) => {
    if (!reviewScore) {
        return mallReviewScore;
    }

    if (!mallReviewScore) {
        return reviewScore;
    }
    return ((reviewScore * reviewCount) + (mallReviewScore * mallReviewCount)) / (reviewCount + mallReviewCount);
}

export const formattedPrice = (price, currency) => {
    if (!Number.isInteger(price)) {
        return price;
    }

    if (currency === 'KRW') {
        return numberWithComma(price);
    } else if (currency === 'USD') {
        let priceText = price.toString();

        while (priceText.length < 3) {
            priceText = '0' + priceText;
        }

        const decimal = priceText.slice(priceText.length - 2);
        const integer = priceText.slice(0, priceText.length - 2);

        return numberWithComma(integer) + '.' + decimal;
    }

    return price;
}

export const localizedPrice = (price, currency) => {
    if (!Number.isInteger(price)) {
        return price;
    }

    if (currency === 'KRW') {
        return numberWithComma(price) + '원';
    } else if (currency === 'USD') {
        return '$' + formattedPrice;
    }

    return price;
}

export const countryToCurrency = (country) => {
    if (country === 'KR') {
        return 'KRW';
    } else if(country === 'US'){
        return 'USD';
    }
    return '';
}

export const leadingZeros = (num, places) => String(num).padStart(places, '0')


export const dashedPhoneNumber = (value, withoutPrefix) => {
    if (!value) return;
    let newValue = value.split('-').join('');
    const prefixLength = withoutPrefix ? 0 : (newValue.startsWith('02') ? 2 : 3);
    const suffixLength = 4;
    if (newValue.length > suffixLength) {
        const slashIndex = newValue.length - suffixLength;
        const frontPart = newValue.slice(0, slashIndex);
        const localNumberPart = frontPart.slice(0, prefixLength);
        const partArray = prefixLength === 0 ? [] : [localNumberPart];
        const middleNumberPart = frontPart.slice(prefixLength);
        if (middleNumberPart) {
            partArray.push(middleNumberPart);
        }
        const backPart = newValue.slice(slashIndex);
        if (backPart) {
            partArray.push(backPart);
        }
        newValue = partArray.join('-');
    }
    return newValue;
}


export const chunkedArray = (arr, size) => {
    if (arr.length === 0) {
        return arr;
    }
    const res = arr.reduce(
        (acc, e, i) => {
            i % size ? acc[acc.length - 1].push(e) : acc.push([e]);
            return acc;
        },
        []
    );
    while (res[res.length - 1].length < size) {
        res[res.length - 1].push(null);
    }
    return res;
}

export const isExpandedRoute = (route) => {
    return (
        isHomeRoute(route)
        && route !== storeRoute
        && !route.startsWith(paletteRoute)
        && !route.startsWith(brandRoute)
        && !route.startsWith(myPageRoute)
        && route !== searchRoute
        && route !== specialOffersRoute
        && route !== '/s/[ccode]'
    );
}


export const formatDate = (date, includeTime) => {
    const d = new Date(date * 1000);
    const month = (d.getMonth() + 1).toString().padStart(2, '0');
    const day = d.getDate().toString().padStart(2, '0');
    const year = d.getFullYear();

    let res = [year, month, day].join('.');

    if (includeTime) {
        const hour = d.getHours().toString().padStart(2, '0');
        const minute = d.getMinutes().toString().padStart(2, '0');
        res += ` ${hour}:${minute}`
    }
    return res;
}


export const formatDateInSlash = (date, includeTime) => {
    const d = new Date(date * 1000);
    const month = (d.getMonth() + 1).toString().padStart(2, '0');
    const day = d.getDate().toString().padStart(2, '0');
    const year = d.getFullYear();

    let res = [month, day].join('/');

    if (includeTime) {
        const hour = d.getHours().toString().padStart(2, '0');
        const minute = d.getMinutes().toString().padStart(2, '0');
        res += ` ${hour}:${minute}`
    }
    return res;
}

const getDayOfWeek = (date) => {
    const dayOfWeek = date.getDay();
    return '일월화수목금토'[dayOfWeek];
}

export const formatDateToMonthDay = (date) => {
    const d = new Date(date * 1000);
    const month = (d.getMonth() + 1).toString();
    const day = d.getDate().toString();
    const dayOfWeek = getDayOfWeek(d);

    let res = [month, day].join('/');
    return res + `(${dayOfWeek})`;
}


export const convertDateToIamportTimeString = (d) => {
    const month = (d.getMonth() + 1).toString().padStart(2, '0');
    const day = d.getDate().toString().padStart(2, '0');
    const year = d.getFullYear();
    const hour = d.getHours().toString().padStart(2, '0');
    const minute = d.getMinutes().toString().padStart(2, '0');
    return [year, month, day, hour, minute].join('');
}

export const getTimeFromTimestamp = (timestamp) => {
    const d = new Date(timestamp * 1000);
    const hour = d.getHours().toString();
    return hour;
};

export function useOutsideAlerter(ref, callback) {
    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                callback(event);
            }
        }

        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref]);
}


export function getPixelValueFromString(str) {
    return Number.parseInt(str.replace('px', ''));
}

export function getPixelStringFromInt(int) {
    return int.toString() + 'px';
}

export function padWithZero(text) {
    return ('0' + text).slice(-2);
}

export function isIOS() {
    return [
            'iPad Simulator',
            'iPhone Simulator',
            'iPod Simulator',
            'iPad',
            'iPhone',
            'iPod'
        ].includes(navigator.platform)
        // iPad on iOS 13 detection
        || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
}

export function compareDictionaries(d1, d2) {
    // quick check for the same object
    if (d1 === d2)
        return true;

    // check for null
    if (d1 == null || d2 == null)
        return false;

    // go through the keys in d1 and check if they're in d2 - also keep a count
    let count = 0;
    for (const key in d1) {
        // check if the key exists
        if (!(key in d2))
            return false;

        // check that the values are the same
        if (d1[key] !== d2[key])
            return false;

        count++;
    }

    // now just make sure d2 has the same number of keys
    let count2 = 0;
    for (const key in d2)
        count2++;

    // return if they're the same size
    return (count === count2);
}

export function throttle(callbackFn, limit) {
    let wait = false;
    return function (e) {
        if (!wait) {
            callbackFn(e);
            wait = true;
            setTimeout(function () {
                wait = false;
                callbackFn(e);
            }, limit);
        }
    }
}


export function breakLines(text) {
    const paragraphList = text.split('\n');

    return text;
    // const children = [];
    // for (const idx in paragraphList) {
    //     children.push(paragraphList[idx]);
    //     if (idx !== paragraphList.length - 1) {
    //         children.push(<br />);
    //     }
    // }
    // return (
    //     <span>
    //         {
    //             children
    //         }
    //     </span>
    // )
}

export function isEmptyDictionary(obj) {
    return Object.keys(obj).length === 0;
}

export const getOffsetTop = element => {
    let offsetTop = 0;
    while (element) {
        offsetTop += element.offsetTop;
        element = element.offsetParent;
    }
    return offsetTop;
}

export function mergeRefs(...refs) {
    return (ref) =>
        refs.forEach((possibleRef) => (possibleRef.current = ref));
}

export const defaultProduct = {
    id: 1,
    is_entered: false,
    thumbnail_url: '',
    name: 'none',
    brand: {name: 'brand'},
    discount_rate: 0,
    options: [],
    price: 0,
    discounted_price: 0
}

export const onFileSelect = (cb) => {
    return async e => {
        if (!e.target.value) return;
        const file = e.target.files.length > 0 ? e.target.files[0] : null;
        if (!file) {
            cb(null);
            return;
        }
        cb(file);
    }
}

export const onFilesSelect = (cb) => {
    return async e => {
        if (!e.target.value) return;
        const files = e.target.files;
        if (files.length === 0) {
            cb(null);
            return;
        }
        cb(Array.from(files));
    }
}

export const readURL = file => {
    return new Promise((res, rej) => {
        const reader = new FileReader();
        reader.onload = e => {
            const image = new Image();
            image.onload = () => {
                res([e.target.result, image.width, image.height]);
            }
            image.onerror = () => {
                rej(e);
            }
            image.src = e.target.result;
        };
        reader.onerror = e => rej(e);
        reader.readAsDataURL(file);
    });
};

export const getYoutubeIdFromUrl = (url) => {
    const rx = /^.*(?:(?:youtu\.be\/|v\/|vi\/|u\/\w\/|embed\/|shorts\/)|(?:(?:watch)?\?v(?:i)?=|\&v(?:i)?=))([^#\&\?]*).*/;
    const match = url.match(rx);
    return match[1];
}

export const checkBatchimEnding = (word) => {
    if (typeof word !== 'string') return null;

    var lastLetter = word[word.length - 1];
    var uni = lastLetter.charCodeAt(0);

    if (uni < 44032 || uni > 55203) return null;

    return (uni - 44032) % 28 !== 0;
}

export const shuffle = (array) => {
    if (array.length === 0) {
        return array;
    }
    let currentIndex = array.length, randomIndex;

    // While there remain elements to shuffle.
    while (currentIndex !== 0) {

        // Pick a remaining element.
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;

        // And swap it with the current element.
        [array[currentIndex], array[randomIndex]] = [
            array[randomIndex], array[currentIndex]];
    }

    return array;
}

export const getLoggingDataFromOptionCount = (optionCount, orderNumber, isLoginPurchase) => {
    return {
        option_id: optionCount.id,
        option_name: optionCount.name,
        product_id: optionCount.product.id,
        product_name: optionCount.product.name,
        brand_id: optionCount.product?.brand.id,
        brand_name: optionCount.product?.brand.name,
        count: optionCount.count,
        price: optionCount.discounted_price,
        referral_id: optionCount.referral_id,
        referral_post_id: optionCount.referral_post_id,
        promotion_id: optionCount.promotion_id,
        order_number: orderNumber,
        order_number_with_option_id: `${orderNumber}_${optionCount.id}`,
        is_login_purchase: isLoginPurchase,
    };
}

export const parseNumInputString = (prevVal, newVal, min, max) => {
    if (!/^\d*$/.test(newVal)) {
        return prevVal;
    }
    if (!newVal) {
        return 0;
    }
    const parsedValue = parseInt(newVal);
    if (parsedValue > max) {
        return max;
    }
    if (parsedValue < min) {
        return min;
    }
    return parsedValue;
}

export const getDaysLeft = (targetDate) => {
    const now = Math.floor(Date.now() / 1000);
    return Math.floor((targetDate - now) / (3600 * 24));
}

// a, b are both array
// Replace a elements with b elements and if a elements are left, concat the rest of a elements
export const replaceAndConcat = (a, b) => {
    const bCopy = JSON.parse(JSON.stringify(b));
    bCopy.push(...a.slice(b.length));
    return bCopy;
};

export const customConfirm = (message, confirmText, cancelText, onConfirm, onCancel) => {
    confirmAlert({
        customUI: ({onClose}) => {
            const runAndClose = (fn) => {
                return () => {
                    fn();
                    onClose();
                };
            }
            return <div className={styles.body}>
                <div className={styles.title}><span>{message}</span></div>
                <div className={styles.buttonWrapper}>
                    {
                        cancelText &&
                        <div className={styles.cancelButton} onClick={runAndClose(onCancel)}><span>{cancelText}</span>
                        </div>
                    }
                    <div className={styles.confirmButton} onClick={runAndClose(onConfirm)}><span>{confirmText}</span>
                    </div>
                </div>
            </div>
        }
    });
};

export const checkHasConsonant = (text) => {
    // text의 마지막 음절의 유니코드(UTF-16)
    const charCode = text.charCodeAt(text.length - 1);

    // 유니코드의 한글 범위 내에서 해당 코드의 받침 확인
    const consonantCode = (charCode - 44032) % 28;

    return consonantCode !== 0;
};

export const highlightMatchedText = (text, keyword) => {
    if (!keyword) return text;
    const keywordList = keyword.split(' ');
    let res = text;
    for (const keyword of keywordList) {
        if (!keyword) continue;
        let regex = null;
        try {
            const normalKeyword = keyword.replace('/', '').replace('\\', '').replace(' ', '');
            regex = new RegExp(normalKeyword.split('').join('\\s*'), 'gi');
            // find matching text
            const matchedText = res.match(regex);
            const splittedText = res.split(regex);
            console.log('regex', regex, matchedText, splittedText);
            let finalText = '';
            for (let i = 0; i < splittedText.length; i++) {
                finalText += splittedText[i];
                if (i < splittedText.length - 1) {
                    finalText += `<span class=${styles.highlight}>${matchedText[i]}</span>`;
                }
            }
            res = finalText;
        } catch (e) {
            console.log(e);
        }
    }
    return res;
};

export const customAlert = (message, confirmText, onConfirm) => {
    customConfirm(message, confirmText, null, onConfirm, () => {
    });
};

export const isProductSoldOut = (product) => {
    let isSoldOut = true;
    for (const option of product.options) {
        if (!option.is_sold_out) {
            isSoldOut = false;
        }
    }
    if (product.options.length === 0) {
        isSoldOut = false;
    }
    return isSoldOut;
};

export const isApp = () => {
    if (typeof window === 'undefined') {
        return false;
    } else {
        return !!window.isRNWebView;
    }
}

export const secondsToString = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secondsLeft = seconds % 60;
    return `${leadingZeros(hours, 2)}:${leadingZeros(minutes, 2)}:${leadingZeros(secondsLeft, 2)}`;
};

function easeOut(currentTime, startValue, changeValue, duration) {
    currentTime /= duration;
    return -changeValue * currentTime * (currentTime - 2) + startValue;
}

function getTransitionSimulator(ele, distance, duration, callback) {
    let handle;
    let resolve;
    return () => {
        let promise = new Promise(res => {
            resolve = res;
        });
        let startTime = performance.now();
        cancelAnimationFrame(handle);

        function _animation() {
            let current = performance.now();
            // distance to move this frame
            let disVal = easeOut(current - startTime, 0, distance, duration);

            callback(ele, disVal);
            if ((current - startTime) / duration < 1) {
                handle = requestAnimationFrame(_animation);
            } else {
                cancelAnimationFrame(handle);
                resolve();
            }
        }

        handle = requestAnimationFrame(_animation);
        return promise;
    };
}

export const swiperCustomAnimation = async (swiper, targetTransVal) => {
    const wrapper = swiper.$wrapperEl[0]; // wrapper element
    // when use transition to do animation
    if (wrapper.style.transitionDuration !== '0ms') {
        // get origin translate value
        const curTransVal = Math.round(swiper.getTranslate());
        // cancel the animation of transition
        wrapper.style.transitionDuration = '';
        wrapper.style.transform = `translate3d(${curTransVal}px, 0px, 0px)`;

        const duration = curTransVal === 0 && targetTransVal === 0 ? 0 : 200;
        // use requestFrameAnimation to do animation my self
        const transSimulator = getTransitionSimulator(wrapper, targetTransVal - curTransVal, duration, (el, val) => {
            if (Math.abs(val) > 1000) {
                el.style.transform = `translate3d(${targetTransVal}px, 0px, 0px)`;
                return;
            }
            let res = curTransVal + val;
            if (Math.abs(res - targetTransVal) < 10) {
                res = targetTransVal;
            }
            el.style.transform = `translate3d(${res}px, 0px, 0px)`;
        });
        await transSimulator();
        if (swiper.onSlideToWrapperTransitionEnd) {
            swiper.onSlideToWrapperTransitionEnd.call(wrapper, {target: wrapper});
        }
        // End the transition, call the callback (simulate the internal implementation of Swiper)
    }
}

export const isIOSChrome = () => {
    if (typeof navigator === 'undefined') return false;
    return /CriOS/i.test(navigator.userAgent) &&
        /iphone|ipod|ipad/i.test(navigator.userAgent);
}

export function getRandomArbitrary(min, max) {
    return Math.random() * (max - min) + min;
}


export function isAndroid() {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    return /android/i.test(userAgent);
}

export const postRNMessage = (message) => {
    if (window.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage(JSON.stringify(message));
    }
}

export const fillArrayToMultipleOfBase = (arr, base) => {
    const length = arr.length;
    const remainder = length % base;
    if (remainder === 0) {
        return arr;
    }
    const fillLength = base - remainder;
    const fillArr = new Array(fillLength).fill(null);
    return arr.concat(fillArr);
};

export function Deeplink({
                             iTunesLink = 'https://itunes.apple.com',
                             playStoreLink = 'https://play.google.com/store/apps',
                             androidPackage = 'com.myapp',
                             useFallback = true,
                             fallbackUrl = 'about:blank',
                         }) {
    const ua = window.navigator.userAgent // 브라우저 user agent string
    const isAndroid = /android/gi.test(ua)
    const isIOS = /iphone|ipad|ipod/gi.test(ua)
    const isMobile = isAndroid || isIOS
    // const isSafariBrowser = isIOS && /version/gi.test(ua)

    const settings = {
        appDownLink: isIOS ? iTunesLink : playStoreLink,
        androidPackage,
        useFallback, // 딥링크가 유효하지 않아 이동에 실패했을때, 앱스토어로 이동할지
        delay: 2000,
        delta: 500,
        fallbackUrl: fallbackUrl,
    }

    let iframeEl
    let fallbackTimer

    /**
     * iOS라면 전달받은 URI 그대로 사용,
     * 안드로이드라면 intent 딥링크를 설정한다.
     */
    const makeURI = uri => {
        if (isAndroid && !navigator.userAgent.match(/Firefox/)) {
            // ex) myapp://test/id
            // matchs[1] === myapp
            // matchs[2] === test/id
            const matches = uri.match(/([^:]+):\/\/(.+)$/i)

            uri = [
                `intent://${matches[2]}#Intent`,
                `scheme=${matches[1]}`,
                `package=${settings.androidPackage}`,
                `S.browser_fallback_url=${encodeURIComponent(settings.fallbackUrl)}`,
                'end',
            ].join(';')
        }

        return uri
    }

    const openApp = uri => {
        if (isIOS || (isAndroid && uri.indexOf('intent') > -1)) {
            document.location.href = uri
        } else {
            openAppWithIframe(uri)
        }
    }

    const openAppStore = () => {
        document.location.href = settings.appDownLink
    }

    const setFallback = now => {
        // 화면이 표시되지 않음 => 딥링크 이동에 성공 => fallback 링크 이동 타이머를 제거한다
        const waitForHidden = setInterval(() => {
            if (document.hidden || document.webkitHidden) {
                clearTimeout(fallbackTimer)
                clearInterval(waitForHidden)
            }
        }, 200)

        return () => {
            clearInterval(waitForHidden)
            const wait = settings.delay + settings.delta

            // setTimeout에 설정한 시간은 settings.delay,
            // 거기에 delta로 스크립트를 실행하는 시간을 충분히 더해준다.
            if (Date.now() - now < wait) {
                window.location.href = settings.appDownLink
            }
        }
    }

    /**
     * 숨겨진 iframe을 만들고 src 속성에 uri를 할당하는 방식을 사용한다.
     * @param {*} uri
     */
    const openAppWithIframe = uri => {
        iframeEl = document.createElement('iframe')
        iframeEl.id = 'test'
        iframeEl.style.width = 0
        iframeEl.style.height = 0
        iframeEl.style.visibility = 'hidden'
        document.body.appendChild(iframeEl)

        iframeEl.onload = function () {
            iframeEl.src = uri
            if (iframeEl.parentNode) {
                iframeEl.parentNode.removeChild(iframeEl)
            }
        }
    }

    return {
        redirectToApp: (target = '') => {
            if (isMobile) {
                openApp(makeURI(target))
            } else {
                openAppStore()
            }

            // 딥링크를 사용할 수 없을 때를 대비해서 앱 다운로드 페이지로 이동하기 위한 타임아웃을 설정한다.
            if (settings.useFallback) {
                fallbackTimer = setTimeout(setFallback(Date.now()), settings.delay)
            }
        },
    }
}

export const formattedDate = (a) => {
    return `${a.getFullYear()}-${(a.getMonth() + 1).toString().padStart(2, '0')}-${a.getDate().toString().padStart(2, '0')} ${a.getHours().toString().padStart(2, '0')}:${a.getMinutes().toString().padStart(2, '0')}:${a.getSeconds().toString().padStart(0, '2')}`
}

export const getCurrentHref = () => {
    if (typeof window === 'undefined') return '';
    return window.location.href.split(window.location.hostname)[1]
}

export const getFullBrandName = (product) => {
    return product ? product.brand_name : "";
}

export const getCurrentTimeKST = () => {
    const timezoneOffset = new Date().getTimezoneOffset() * 60000;
    return new Date(Date.now() - timezoneOffset);
};

export const getCurrentTimeKSTString = () => {
    const timezoneOffset = new Date().getTimezoneOffset() * 60000;
    const timezoneDate = new Date(Date.now() - timezoneOffset);
    return timezoneDate.toISOString();
};

const dateUnit = {
    'KR': {
        year: '년',
        month: '개월',
        day: '일',
    },
    'US': {
        year: 'year',
        month: 'month',
        day: 'day',
    }
}

export const getExpiresAtDateFromDMY = (day, month, year, country) => {
    let expiresAt = '';
    if (Boolean(year)) {
        expiresAt += `${year}${dateUnit[country].year}`;
    }
    if (Boolean(month)) {
        expiresAt += ` ${month}${dateUnit[country].month}`;
    }
    if (Boolean(day)) {
        expiresAt += ` ${day}${dateUnit[country].day}`;
    }
    return expiresAt;
};

export function isMobile() {
    return isIOS() || isAndroid();
}

export const findNextInputAndFocus = (sourceElement) => {
    if (!sourceElement) return;
    const elems = document.querySelectorAll(`input.${INPUT_CLASS_NAME}`);
    let index = -1;
    for (let i in elems) {
        const elem = elems[i];
        if (elem === sourceElement) {
            index = parseInt(i);
        }
    }
    if (index === -1 || index === elems.length - 1) {
        return;
    }
    const nextElem = elems[index + 1];
    if (nextElem) {
        nextElem.focus();
    }
}

export const isValidPhoneNumber = (phoneNumber) => {
    if (!phoneNumber) return false;
    return phoneNumber.split('-').join('').length === 11 && phoneNumber.startsWith('010');
}

export function openIosKeyboard() {
    const input = document.createElement("input");
    input.setAttribute("type", "text");
    input.setAttribute("style", "position: fixed; top: -100px; left: -100px;");
    document.body.appendChild(input);
    input.focus();
    // it's safe to remove the fake input after a 30s timeout
    setTimeout(() => {
        document.body.removeChild(input);
    }, 30 * 1000);
}

export function getScrollKeyForRouteKey(routeKey) {
    return `__next_scroll_${routeKey}`;
}

export function showRewardToast(
    router, rewardKey, point, customTitle = null, customDescription = null, closeButtonText = '포인트 더 받기'
) {
    const title = customTitle ? customTitle.replace('{point}', point) : `${point}P를 받았어요!`;
    const description = customDescription ? customDescription.replace('{point}', point) : '포인트를 더 받아보세요';

    typeof mixpanel !== 'undefined' && mixpanel.track('RewardToastShown', {key: rewardKey});
    toast.info(
        <ToastContent
            title={title} description={description} closeButton={closeButtonText}
            onClose={() => {
                typeof mixpanel !== 'undefined' && mixpanel.track('RewardToastReceiveMoreClick', {key: rewardKey});
                router.push(rewardsRoute)
            }}/>,
        {containerId: 'new', autoClose: 5000}
    );
}

export const showToast = (title, description, closeButton = '확인', onClose = null) => {
    typeof mixpanel !== 'undefined' && mixpanel.track('ToastShown', {title, description});
    toastContextRef.current?.closeAndOpenToast(
        <ToastContent
            title={title} description={description} closeButton={closeButton}
            onClose={() => {
                onClose && onClose();
            }}
        />,
        {containerId: 'new', autoClose: 5000}
    )
};

const jauemIndexList = ['ㄱ','ㄱ','ㄴ','ㄷ','ㄷ','ㄹ','ㅁ','ㅂ','ㅂ','ㅅ','ㅅ','ㅇ','ㅈ','ㅈ','ㅊ','ㅋ','ㅌ','ㅍ','ㅎ',];

export const getFirstCharacterIndex = (word) => {
    try {
        const alphaNumeric = /^[A-Za-z0-9]*$/;
        if (word.length === 0) {
            return '#';
        }
        if (alphaNumeric.test(word[0])) {
            return '#';
        }

        const unit = word.charCodeAt(0) - 0xac00;
        const last = unit % 28;
        const mid = ((unit - last) / 28) % 21;
        const first = (((unit - last) / 28) - mid) / 21;
        return jauemIndexList[first];
    } catch (e) {
        console.log(word, 'exception', e);
    }
};


export const getJsonLocalStorageByKey = (key) => {
    if (typeof window !== 'undefined' && window?.localStorage.getItem(key))
        return JSON.parse(window?.localStorage.getItem(key));
    return {};
}

export const saveCountryCode = (countryCode) => {
    window.localStorage.setItem('country_code', countryCode);

};

export const getLocalStorageCountryCode = () => {
    return COUNTRY_CODE;
};
