import styles from "components/textbox/LabelText.module.scss";
import colors from "styles/color.module.scss";

const LabelText = (props) => {
    const {content, backgroundColor} = props;
    return (
        <div className={styles.root} style={{backgroundColor: backgroundColor === 'brand' ? colors.brand : backgroundColor}}>
            <span className={styles.content}>{content}</span>
        </div>
    )
};

export default LabelText;
