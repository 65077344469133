import styles from 'components/cards/coupon/DownloadableCouponCard.module.scss';
import DownloadIcon from "images/icons/download.svg";
import classNames from "classnames";
import {Axios} from "api";
import {redirectToApp, redirectToLogin} from "common/redirect";
import {captureException, captureMessage} from "@sentry/nextjs";
import {toast} from "react-toastify";
import {useContext, useEffect, useMemo, useState} from "react";
import {useRouter} from "next/router";
import {COUPON_TYPE_PERCENTAGE} from "components/cards/CheckoutCouponCard";
import UserContext from "context/AuthContext";
import {
    countryToCurrency,
    customAlert,
    customConfirm,
    formatDate, formattedPrice,
    getExpiresAtDateFromDMY,
    isAndroid,
    isApp,
    isIOS,
    numberWithComma,
} from "common/utils";
import utilStyles from "styles/utils.module.scss";
import CheckIcon from "images/icons/check_grey.svg";
import useTranslation from 'next-translate/useTranslation';
import i18nContext from "context/I18nContext";


export const handleCouponDownload = async (couponMold, isLoading, setIsLoading, onSuccess, router, user, countryCode, t) => {
    typeof mixpanel !== 'undefined' && mixpanel.track('DownloadableCouponCardCouponDownload', {
        mold_key: couponMold.mold_key,
        is_app_only: couponMold.is_app_only,
    });

    if (isLoading) {
        return false;
    }

    if (!couponMold.is_obtainable) {
        toast.info(t('DownloadableCouponCard.alreadyDownloaded'));
        return false;
    }

    if (couponMold.is_app_only && !isApp()) {
        if (isIOS() || isAndroid()) {
            customConfirm(
                t('DownloadableCouponCard.appOnlyConfirm'),
                t('DownloadableCouponCard.confirm'),
                t('DownloadableCouponCard.cancel'),
                () => {
                    redirectToApp();
                },
                () => {}
            );
        } else {
            customAlert(
                t('DownloadableCouponCard.appOnlyAlert'),
                t('DownloadableCouponCard.confirm'),
                () => {}
            );
        }
        return false;
    }

    if (!user) {
        redirectToLogin(router, true);
        return false;
    }

    setIsLoading(true);
    try {
        const data = {
            mold_key: couponMold.mold_key,
        }
        const res = await Axios.post('v1/coupons/download', data);
        if (res.status < 400) {
            onSuccess(res);
            return true;
        } else if (res.status === 401) {
            close();
            redirectToLogin(router, true);
        } else {
            captureMessage(JSON.stringify(res.data));
            toast.info(res.data.display_message || t('DownloadableCouponCard.errorMessage'));
        }
        return false;
    } catch (e) {
        captureException(e);
        toast.info(t('DownloadableCouponCard.errorMessage'));

        return false;
    } finally {
        setIsLoading(false);
    }
};

export const getConditionText = (couponMold, t, countryCode) => {
    const currency = countryToCurrency(countryCode);
    const couponAmount = couponMold.type === COUPON_TYPE_PERCENTAGE ?
        `${couponMold.amount}%` :
        `${numberWithComma(couponMold.amount)}${t(`common:currency.${currency}`)}`;

    const couponExpiresAt = couponMold.expiration_type === 'fixed_date' ?
        t('DownloadableCouponCard.expiresAtFixed', { date: formatDate(couponMold.expires_at, false) }) :
        t('DownloadableCouponCard.expiresAtFromIssue', {
            duration: getExpiresAtDateFromDMY(couponMold.valid_day, couponMold.valid_month, couponMold.valid_year, countryCode)
        });

    const condition = couponMold.min_amount ? (
        couponMold.max_amount ?
            t('DownloadableCouponCard.minPurchaseWithMax', {
                minAmount: formattedPrice(couponMold.min_amount, currency),
                maxAmount: formattedPrice(couponMold.max_amount, currency)
            }) :
            t('DownloadableCouponCard.minPurchase', {
                minAmount: formattedPrice(couponMold.min_amount, currency)
            })
    ) : (
        couponMold.max_amount ?
            t('DownloadableCouponCard.maxDiscount', {
                maxAmount: formattedPrice(couponMold.max_amount, currency)
            }) :
            null
    );

    return {
        couponAmount,
        couponExpiresAt,
        condition,
    }
}

const DownloadableCouponCard = (props) => {
    const {couponMold, onSuccess, hasDownloaded, setDownloadedCoupons} = props;

    const isInactive = hasDownloaded || !couponMold.is_obtainable;

    const [isLoading, setIsLoading] = useState(false);
    const router = useRouter();
    const user = useContext(UserContext).user;
    const {countryCode} = useContext(i18nContext);
    const { t } = useTranslation('components-cards-coupon-DownloadableCouponCard');

    const _handleCouponDownload = () => {
        handleCouponDownload(
            couponMold, isLoading, setIsLoading, onSuccess, router, user, countryCode, t
        ).then(
            (isSuccess) => {
                if (isSuccess && Boolean(setDownloadedCoupons)) {
                    setDownloadedCoupons(prev => [...prev, couponMold.mold_key]);
                }
            }
        );
    }

    const {couponAmount, couponExpiresAt, condition} = getConditionText(couponMold, t, countryCode);

    const couponAmountText = useMemo(() => {
        if (!Boolean(couponMold) || !Boolean(countryCode)) {
            return '';
        }
        const currency= countryToCurrency(countryCode);

        return couponMold.type === COUPON_TYPE_PERCENTAGE ?
            `${couponMold.amount}%` :
            `${formattedPrice(couponMold.amount, countryToCurrency(countryCode))}${t(`common:currency.${currency}`)}`;
    }, [couponMold, countryCode, couponAmount])

    // const couponAmountText = couponMold.type === COUPON_TYPE_PERCENTAGE ?
    //     `${couponMold.amount}%` :
    //     `${formattedPrice(couponMold.amount, countryToCurrency(countryCode))}${t(`common:currency.${countryCode}`)}`;

    return (
        <div
            className={classNames(styles.root, isInactive && styles.inactiveRoot)}
            onClick={_handleCouponDownload}
        >
            <div className={styles.content}>
                <div className={classNames(utilStyles.flexRow, utilStyles.alignItemsCenter, (
                    couponMold.is_app_only || couponMold.is_gift_only || couponMold.is_duplication_allowed
                ) ? styles.tagsContainer : undefined, isInactive && styles.inactiveTagsContainer)}>
                    {
                        couponMold.is_app_only &&
                        <div className={
                            classNames(styles.appOnlyTag)
                        }>
                            {t('DownloadableCouponCard.appOnly')}
                        </div>
                    }
                    {
                        couponMold.is_gift_only &&
                        <div className={styles.giftOnlyLabel}>
                            {t('DownloadableCouponCard.giftOnly')}
                        </div>
                    }
                    {
                        couponMold.is_duplication_allowed &&
                        <div className={styles.duplicateAllowedLabel}>
                            {t('DownloadableCouponCard.unlimitedIssue')}
                        </div>
                    }
                </div>
                <div className={styles.title}>
                    <div className={classNames(styles.discountAmount, isInactive && styles.inactiveTitle)}>
                        {couponAmountText}
                    </div>
                </div>
                <div className={styles.descriptionContainer}>
                    {/*{*/}
                    {/*    couponMold.is_brand_coupon &&*/}
                    {/*    <span className={classNames(styles.brandCoupon, isInactive && styles.inactiveText)}>[브랜드 쿠폰]</span>*/}
                    {/*}*/}
                    <span
                        className={classNames(styles.description, isInactive && styles.inactiveDescription)}>{couponMold.name}</span>
                </div>
                {
                    condition &&
                    <div className={classNames(styles.conditionText, isInactive && styles.inactiveConditionText)}>
                        {condition}
                    </div>
                }
                <div className={classNames(styles.expiresAt, condition && styles.expiresAtWithCondition)}>
                    {couponExpiresAt}
                </div>
            </div>
            <div className={classNames(styles.downloadButton, isInactive && styles.downloadButtonInactive)}>
                {
                    hasDownloaded ?
                        <CheckIcon viewBox="0 0 16 16" className={styles.checkIcon}/> :
                        <DownloadIcon viewBox="0 0 19 18"
                                      className={classNames(styles.downloadIcon, isInactive && styles.inactiveDownloadIcon)}/>
                }
            </div>
        </div>
    )
};

export default DownloadableCouponCard;
