import React, {useMemo, useRef} from 'react';
import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/css';
import 'swiper/css/autoplay';
import {Autoplay} from 'swiper';
import styles from "components/layout/store/products/RotateProducts.module.scss";
import ProductListCard from "components/cards/ProductListCard";
import {Box} from "@material-ui/core";

export const RotateProducts = (props) => {
    const {
        products, onProductClick, hidePriceInfo, width, imageRatio, productNameMaxLine,
        miniScrapIcon, onShowMoreClick, onPouchAdd, isAddToPouchVisible, highlightKeyword, showRank,
        useBestCouponDiscount, theme, reverse, top, productIdToDescription,
    } = props;
    const swiperRef = useRef(null);

    const productIdToDescriptions = useMemo(() => {
        return productIdToDescription || {};
    }, [productIdToDescription]);

    return (
        <div className={styles[`theme-${theme}`]}>
            <div className={styles.swiper_container}>
                <Swiper
                    spaceBetween={8}
                    onSwiper={(swiper) => {
                        swiperRef.current = swiper;
                        swiper.$wrapperEl[0].style.transitionTimingFunction = "linear";
                    }}
                    centeredSlides={true}
                    autoplay={{
                        delay: 0,
                        disableOnInteraction: false,
                        reverseDirection: Boolean(reverse),
                    }}
                    onBeforeDestroy={(swiper) => {
                        if (swiper.autoplay) {
                            swiper.autoplay.stop();
                        }
                    }}
                    modules={[Autoplay]}
                    speed={5000}
                    className="mySwiper"
                    slidesPerView={2}
                    loop={true}
                    onTouchEnd={(swiper) => {
                        swiper.autoplay.start();
                    }}
                >
                    {
                        products.map((e, index) => {
                            return (
                                <div>
                                    <SwiperSlide style={{width: "fit-content"}}>
                                        <div style={{minWidth: 186}}>
                                            <div className={styles.swiperSliderDescriptionWrapper}>
                                                <span>
                                                    {productIdToDescriptions[e?.id] || ''}
                                                </span>
                                            </div>
                                            <div>
                                                <ProductListCard
                                                    onCloseOptionSelectorModal={() => onPouchAdd(e)}
                                                    key={`product-${e.id}`}
                                                    product={e}
                                                    onClick={() => onProductClick(e, index)}
                                                    hasAddToPouchButton={isAddToPouchVisible}
                                                    useBestCouponDiscount={useBestCouponDiscount}
                                                    theme={theme}
                                                />
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                </div>
                            )
                        })
                    }
                </Swiper>
            </div>
        </div>
    );
}


export default RotateProducts;
