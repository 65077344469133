import Image from "next/image";
import styles from "components/cards/simplePostListCard.module.scss";
import utilStyles from "styles/utils.module.scss";
import classNames from "classnames";
import Link from "next/link";
import {getCreatorRoute, paletteRoute} from "common/const";
import PostScrapButton from "components/buttons/PostScrapButton";
import EmptyHeartIcon from "images/icons/heart_empty.svg";
import FilledHeartICon from "images/icons/heart_fill.svg";
import PlusIcon from "images/icons/plus_brand_bg.svg";
import MultiImageIcon from "images/icons/multi_image.svg";
import YoutubeIcon from "images/icons/youtube.svg";
import VideoIcon from "images/icons/video.svg";
import TruncateMarkup from "react-truncate-markup";
import {YoutubeThumbnail} from "components/media/youtube";
import {VideoThumbnail} from "components/media/video";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {useMemo} from "react";
import DefaultProfileIcon from "images/icons/default_profile.svg";
import Nickname from "components/Nickname";
import {useRouter} from "next/router";
import RollingImage from "components/images/RollingImage";

export const SimplePostText = (props) => {
    const {postId, text, maxLines, showProduct} = props;

    const onTruncate = props.onTruncate || function () {
    };

    const textHelper = useMemo(() => {
        if (!text) return '';
        const chunk = text?.split(/(#\S+)/);
        return chunk.map((e, idx) => {
            if (e.startsWith('#')) {
                return (
                    <span className={styles.hashTag} key={`post_${postId}-${idx}`}>{e}</span>
                );
            }
            return (
                <span key={`post_${postId}-${idx}`} className={styles.descriptionText}>
                    {e}
                </span>
            );
        });
    }, [text]);

    return (
        <TruncateMarkup
            key={'undefined'} onTruncate={onTruncate}
            lines={maxLines}
            ellipsis={<span>...</span>}
        >
            <span className={classNames(styles.content, showProduct ? styles.showProductPostText : undefined)}>
                <span className={utilStyles.verticalMiddle} style={{display: 'inline'}}>
                    {/*{text}*/}
                    {textHelper}
                </span>

            </span>
        </TruncateMarkup>
    );
}


const MediaIcon = ({post, size, left, top}) => {

    const getIcon = () => {
        // console.log('post.media.type', post.media.type)
        switch (post.media.type) {
            case 'image':
                if (post.media_count > 1) {
                    return <MultiImageIcon
                        viewBox="0 0 24 24"
                        width={size || 24}
                        height={size || 24}
                    />
                }
                return <div/>
            case 'video':
                return <VideoIcon
                    viewBox="0 0 24 24"
                    width={size || 24}
                    height={size || 24}
                />
            case 'youtube':
                return <YoutubeIcon
                    viewBox="0 0 24 24"
                    width={size || 24}
                    height={size || 24}
                />
            default:
                return <div/>
        }
    }

    return (
        <div className={classNames(styles.mediaIcon)} style={{left, top}}>
            {getIcon()}
        </div>
    )
}


export default function SimplePostListCard(props) {
    const {
        post,
        hideText,
        creatorPage,
        hideScrapButton,
        applyMediaSize,
        marginBottom,
        showProduct,
        showInlineProduct,
        linkOverride,
        miniIcon,
        imageRatio,
        noBorderRadius,
        hideTextAndProduct,
        showUser,
        mediaIconPos,
        forceSquare,
    } = props;
    const maxLine = 2;

    const isMobile = useMediaQuery(`(max-width:${utilStyles.breakpointMobile})`);

    const mediaSize = post.media_size;

    const onPostClick = props.onPostClick || function () {};

    const height = useMemo(
        () => `${Math.round(10000 * mediaSize.height / mediaSize.width) / 100}%`, []
    );

    const getMedia = () => {
        switch (post.media.type) {
            case "image":
                return <Image unoptimized src={post.media.thumbnail_url} alt={post.text} layout="fill"
                              objectFit="cover"
                              style={{lineHeight: 0}}/>
            case "youtube":
                return <>
                    <div className={styles.overlay} onClick={e => {
                        e.stopPropagation()
                    }}/>
                    {
                        post.media.thumbnail_url
                            ? <Image unoptimized src={post.media.thumbnail_url} alt={post.text} layout="fill"
                                     objectFit="cover"
                                     style={{lineHeight: 0}}/>
                            : <YoutubeThumbnail sharpBorder={creatorPage && !isMobile} item={post.media} cover
                                                height={height}/>
                    }
                </>
            default:
                return <>
                    <div className={styles.overlay} onClick={e => {
                        e.stopPropagation()
                    }}/>
                    {
                        post.media.thumbnail_url ?
                            <Image unoptimized src={post.media.thumbnail_url} alt={'media'} layout="fill"
                                   objectFit="cover"
                            /> :
                            <VideoThumbnail sharpBorder={creatorPage && !isMobile} item={post.media} height={height}/>
                    }
                </>
        }
    };

    const router = useRouter();

    const moveToCreatorPage = (code) => {
        typeof mixpanel !== 'undefined' && mixpanel.track(
            'PostCardCreatorClick',
            {
                postId: item?.id,
            }
        );
        if (!code) return;
        for (const key in window.history.state) {
            if (key.toLowerCase().includes('modal')) {
                router.replace(getCreatorRoute(code));
                return
            }
        }
        router.push(getCreatorRoute(code));
    };


    const onScrap = () => {
        post.scrap_count += 1;
    };

    const onUnscrap = () => {
        post.scrap_count -= 1;
    };

    const isAllImages = post.media_list && post.media_list.filter((e) => e.type !== 'image').length === 0;
    const imageList = post.media_list ? post.media_list.map(x => x.thumbnail_url) : [];

    return (
        <>
            <Link href={linkOverride || `${paletteRoute}/${post.id}`}>
                <a className={classNames(utilStyles.cursorPointer)} onClick={() => {onPostClick(post)}}>
                    <div className={classNames(utilStyles.flexCol, utilStyles.relative)}>
                        <div className={classNames(utilStyles.square, noBorderRadius ? utilStyles.noBorderRadius : undefined)}
                             style={{
                                 borderRadius: creatorPage && !isMobile ? 0 : undefined,
                                 paddingBottom: forceSquare ? '100%' : (imageRatio || (applyMediaSize ? height : undefined)),
                             }}
                        >
                            {
                                (!Boolean(post.media) || isAllImages && imageList) ?
                                <RollingImage prefix={"images"} images={imageList} /> :
                                getMedia()
                            }
                        </div>
                        {post.media && <MediaIcon
                            post={post} size={mediaIconPos?.size || (miniIcon ? 16 : undefined)}
                            left={mediaIconPos?.left || (miniIcon ? 6 : undefined)}
                            top={mediaIconPos?.top || (miniIcon ? 6 : undefined)}
                        />}
                        {
                            !hideScrapButton &&
                            <div className={classNames(styles.likeContainer, miniIcon ? styles.miniLikeContainer : undefined)}
                                 style={{top: creatorPage ? 12 : undefined, right: creatorPage ? 12 : undefined}}
                                 onClick={(e) => {
                                     e.stopPropagation();
                                     e.preventDefault();
                                 }}
                            >
                                <PostScrapButton
                                    postId={post.id}
                                    renderScrapped={() => <FilledHeartICon
                                        viewBox="0 0 24 24"
                                        className={styles.likeIcon}
                                        width={miniIcon ? 16 : 24}
                                        height={miniIcon ? 16 : 24}
                                    />}
                                    renderUnscrapped={() => {
                                        if (creatorPage) {
                                            return <EmptyHeartIcon
                                                viewBox="0 0 24 24" className={styles.likeIcon}
                                                width={miniIcon ? 16 : 24}
                                                height={miniIcon ? 16 : 24}
                                            />
                                        }
                                        return <EmptyHeartIcon
                                            viewBox="0 0 24 24" className={styles.likeIcon}
                                            width={miniIcon ? 16 : 24}
                                            height={miniIcon ? 16 : 24}
                                        />
                                    }}
                                    onScrap={onScrap}
                                    onUnscrap={onUnscrap}
                                />
                            </div>
                        }
                        {
                            showInlineProduct && post.product_options.length > 0 &&
                            <div className={styles.inlineProductContainer}>
                                <div className={styles.productWrapper}>
                                    <div className={styles.inlineProductImage}>
                                        <Image unoptimized src={post.product_options[0].image_url}
                                               alt={post.product_options[0].name} layout="fill" />
                                    </div>
                                    {
                                        post.product_options.length > 1 &&
                                        <PlusIcon className={styles.inlineProductPlusIcon} viewBox="0 0 12 12"/>
                                    }
                                </div>
                            </div>
                        }
                        {
                            showUser && post.user &&
                            <div className={styles.profileContainer}>
                                {
                                    post.user.profile_image_url
                                        ?
                                        <div onClick={() => moveToCreatorPage(post.user.code)} className={classNames(styles.profileImage)}>
                                            <Image unoptimized src={post.user.profile_image_url} width={32} height={32} />
                                        </div>
                                        : <DefaultProfileIcon onClick={() => moveToCreatorPage(post.user.code)} className={styles.profileImage} viewBox="0 0 32 32" />
                                }
                                <div className={styles.userInfoContainer}>
                                    <Nickname value={`${post.user.nickname}`} creatorCode={post.user.code} className={styles.userNickname} />
                                        {/*{*/}
                                        {/*    post.user.is_verified &&*/}
                                        {/*    <CreatorBadgeIcon className={classNames(utilStyles.verticalMiddle, styles.creatorBadge)} viewBox="0 0 16 16" />*/}
                                        {/*}*/}
                                </div>
                            </div>
                        }
                    </div>
                    {
                        !hideTextAndProduct &&
                        <div className={styles.textContainer}>
                            {
                                showProduct && post && post.product_options && post.product_options.length > 0 &&
                                <div className={styles.productContainer}>
                                    <div className={styles.productWrapper}>
                                        <div className={styles.productImage}>
                                            <Image unoptimized src={post.product_options[0].image_url}
                                                   alt={post.product_options[0].name} layout="fill" />
                                        </div>
                                        {
                                            post.product_options.length > 1 &&
                                            <PlusIcon className={styles.plusIcon}/>
                                        }
                                    </div>
                                </div>
                            }
                            {
                                !hideText &&
                                <div className={styles.infoContainer}
                                     style={{marginTop: creatorPage ? (isMobile ? 12 : 16) : undefined}}>
                                    <div
                                        className={classNames(styles.name, showProduct ? styles.showProductPostText : undefined)}>
                                        <SimplePostText postId={post.id} text={post.text} maxLines={maxLine} showProduct/>
                                    </div>
                                </div>
                            }
                        </div>
                    }
                    {
                        marginBottom &&
                        <div style={{height: marginBottom}}/>
                    }
                </a>
            </Link>
        </>
    )
}

export const SimplePostCardPlaceholder = ({showProduct}) => {
    return (
        <div className={classNames(utilStyles.flexCol, utilStyles.relative)}>
            <div className={classNames(utilStyles.square, styles.placeholder)}>
                <div className={styles.placeholderImage}/>
            </div>
            <div className={classNames(styles.textContainer, utilStyles.flexRow, utilStyles.alignItemsCenter)}
                 style={{alignItems: 'center', paddingBottom: '12px'}}
            >
                {
                    showProduct &&
                    <div className={styles.productContainer}>
                        <div className={styles.productWrapper}>
                            <div className={classNames(styles.productImage, styles.placeholderProductWrapper)}>
                                <div className={styles.placeholderProduct}/>
                            </div>
                        </div>
                    </div>
                }
                <div className={classNames(styles.infoContainer)}>
                    <div className={styles.name}>
                        <div className={styles.placeholderText}/>
                    </div>
                </div>
            </div>
        </div>
    )
};
