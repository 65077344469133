const moduleExports = {
    "locales": ["en", "ko"],
    "defaultLocale": "en",
    "localeDetection": false,
    "domains": [
        {
            "domain": process.env.KR_DOMAIN,
            "defaultLocale": "ko"
        },
        {
            "domain": process.env.US_DOMAIN,
            "defaultLocale": "en"
        },
        {
            "domain": process.env.JP_DOMAIN,
            "defaultLocale": "ja"
        }
    ],
    "pages": {
        "*": [
            "common", "components-cards-ProductListCard", "components-cards-VerticalProductListCard", "components-modal-MainCouponModal",
            "components-modal-FeedOptionModal", "components-modal-OptionSelectorPouchAddedModal", "components-input-DirectPurchaseModal",
            "components-cards-palette-PostOptionCard", "auth", "components-buttons-AddPostButton"
        ],
        "/": ["home", "components-shortcut"],
        "/home": ["home", "components-shortcut"],
        "rgx:/auth/*": [
            "input-SignUpAgreement", "components-buttons-AppleLoginButton", "components-cards-CheckoutCouponCard",
            "components-cards-coupon-DownloadableCouponCardV2",
            "components-cards-coupon-DownloadableCouponCard"
        ],
        "/events": ["events-index", "components-buttons-SubscriptionButton"],
        "/palette": ["palette-index", "components-modal-EditPostModal", "components-bar-PaletteTopBar"],
        "/gift": ["gift",],
        "/search": [
            "search", "components-sections-SearchSection", "components-sections-search-SearchHome",
            "layout-TopBar-Search", "search-OverallStoreSection", "sections-search-NoSearchResult",
            "sections-search-LoadingSearchResult", "sections-search-SectionTitle",
            "sections-search-store-StoreSearchFilter", "sections-search-store-StoreSearchSortOrder",
            "sections-search-store-StoreSearchResult", "components-sections-AllSearchResult",
            "sections-search-BrandSearchItemCard", "events-index",
            "components-buttons-SubscriptionButton"
        ],
        "/seller-shop": ["seller-shop",],
        "rgx:/mypage*": [
            "mypage", "components-cards-MyInfoCard", "components-cards-BaseOrderOptionCard", "components-cards-CheckoutCouponCard",
            "components-cards-coupon-DownloadableCouponCard"
        ],
        "/reservation": ["reservation",],
        "/rewards": ["rewards-index"],
        "/s": ["seller-shop",],
        "/mypage/likes": ["likes", "components-input-OptionSelector", "components-buttons-ReviewScrapButton"],
        "/mypage/addresses": ["components-modal-DeliveryAddressModal",],
        "/maypage/coupons": ["mypage"],
        "/mypage/point-logs": ["point-logs",],
        "/mypage/orders": [
            "mypage-orders", 'component-review-modal', "components-cards-BaseOrderOptionCard",
            "components-buttons-OrderButton", "component-cards-order-OrderCard", "components-OrderStatusInfo",
            "modal-CropModal", "cards-order-OrderRefundCard", "cards-order-OrderExchangeCard"
        ],
        "/mypage/product-inquiries": ["mypage-product-inquiries", "components-cards-FAQCard"],
        "/mypage/inquiries": ["mypage", "components-cards-FAQCard"],
        "/mypage/refund-account": ["mypage-refund-account",],
        "/mypage/reviews": [
            "mypage-reviews", "component-review-modal", "components-buttons-ReviewScrapButton",
            "components-sections-product-ProductReviewSection", "modal-CropModal"
        ],
        "/mypage/reviews/groups/[gid]": [
            "mypage-reviews", "component-review-modal", "components-buttons-ReviewScrapButton",
            "components-sections-product-ProductReviewSection", "modal-CropModal"
        ],
        "/mypage/reviews/write/[rid]": [
            "mypage-reviews", "component-review-modal", "components-buttons-ReviewScrapButton",
            "components-sections-product-ProductReviewSection", "modal-CropModal"
        ],
        "/mypage/seller-dashboard": ["mypage-seller-dashboard",],
        "/mypage/withdraw": ["mypage-withdraw",],
        "/mypage/withdraw-complete": ["mypage-withdraw-complete",],
        "/mypage/verify-pro": ["mypage-verify-pro",],
        "/mypage/orders/[ordNum]": [
            "mypage-orders-ordNum-index", "component-review-modal", "components-cards-BaseOrderOptionCard",
            "components-buttons-OrderButton", "components-cards-CheckoutAddressCard", "components-cards-CheckoutCouponCard",
            "components-modal-DeliveryAddressModal",
            "cards-order-OrderRefundCard",
            "cards-order-OrderExchangeCard",
        ],
        "/mypage/orders/[ordNum]/gift": [
            "mypage-orders-ordNum-gift", "components-cards-BaseOrderOptionCard", "components-buttons-OrderButton",
            "components-cards-CheckoutCouponCard"
        ],
        "/mypage/orders/[ordNum]/exchanges/[eid]": [
            "mypage-orders-ordNum-exchanges-eid", "components-cards-BaseOrderOptionCard",
            "cards-order-OrderExchangeCard"
        ],
        "/mypage/orders/[ordNum]/options/[optionId]/cancel-order/1": ["mypage-orders-ordNum-options-optionId-cancel-order-1", "components-cards-BaseOrderOptionCard"],
        "/mypage/orders/[ordNum]/options/[optionId]/cancel-order/done": ["mypage-orders-ordNum-options-optionId-cancel-order-done"],
        "/mypage/orders/[ordNum]/options/[optionId]/exchange-order/1": [
            "mypage-orders-ordNum-options-optionId-exchange-order-1", "components-input-OptionSelector",
            "dropdown-OptionSelectionDropdownWithQuantitySelector"
        ],
        "/mypage/orders/[ordNum]/options/[optionId]/exchange-order/2": [
            "mypage-orders-ordNum-options-optionId-exchange-order-2", "components-modal-DeliveryAddressModal",
            "components-sections-ClaimCaution",
            "components-cards-CheckoutAddressCard"
        ],
        "/mypage/orders/[ordNum]/options/[optionId]/exchange-order/done": ["mypage-orders-ordNum-options-optionId-exchange-order-done"],
        "/mypage/orders/[ordNum]/options/[optionId]/refund-order/1": ["mypage-orders-ordNum-options-optionId-refund-order-1"],
        "/mypage/orders/[ordNum]/options/[optionId]/refund-order/2": [
            "mypage-orders-ordNum-options-optionId-refund-order-2", "components-sections-ClaimCaution"
        ],
        "/mypage/orders/[ordNum]/options/[optionId]/refund-order/done": ["mypage-orders-ordNum-options-optionId-refund-order-done"],
        "/mypage/orders/[ordNum]/options/[optionId]/cancel-detail": ["mypage-orders-ordNum-options-optionId-cancel-detail"],
        "/mypage/orders/[ordNum]/refunds/[rid]": ["mypage-orders-ordNum-refunds-rid", "components-cards-BaseOrderOptionCard", "cards-order-OrderRefundCard"],
        "/store/daily-pick": ["store-daily-pick"],
        "/store/products": [
            "store-products-index", "components-modal-OptionSelectorPouchAddedModal", "components-layout-NavBar-StoreNavBar"
        ],
        "/store/products/[pid]": [
            "store-products-index", "components-buttons-PurchaseButton", "components-sections-product-ProductInfoSection",
            "components-input-OptionSelector", "components-modal-OptionSelectorPouchAddedModal", "components-pages-ProductDetail",
            "component-review-modal", "components-sections-product-ProductReviewSection", "components-buttons-ReviewScrapButton",
            "components-buttons-SubscriptionButton", "components-cards-FAQCard",
            "product-ProductInquirySection", 'modal-ProductInquiryModal', "components-modal-ProductDetailCouponDownloadModal",
            "components-cards-coupon-DownloadableCouponCard"
        ],
        "/store/products/[pid]/options/[oid]": [
            "store-products-index", "components-buttons-PurchaseButton", "components-sections-product-ProductInfoSection",
            "components-input-OptionSelector", "components-modal-OptionSelectorPouchAddedModal", "components-pages-ProductDetail",
            "component-review-modal", "components-sections-product-ProductReviewSection", "components-buttons-ReviewScrapButton",
            "components-buttons-SubscriptionButton", "components-cards-FAQCard",
            "product-ProductInquirySection", 'modal-ProductInquiryModal', "components-modal-ProductDetailCouponDownloadModal",
            "components-cards-coupon-DownloadableCouponCard", "components-cards-CheckoutCouponCard"
        ],
        "/store/special-offers": ["store-special-offers", "components-layout-NavBar-StoreNavBar"],
        "/store/professionals": ["store-professionals", "components-layout-NavBar-StoreNavBar"],
        "/store/pouch": ["store-pouch", "components-input-OptionSelector"],
        "/store/find-order": ["store-find-order"],
        "/store/orders/[ordNum]/fail": ["store-orders-ordNum-fail"],
        "/store/global-orders/[ordNum]/fail": ["store-orders-ordNum-fail"],
        "/store/brands": ["store-brands-index"],
        "/store/brands/[bid]": ["store-brands-bid"],
        "/achi": ["store-brands-bid"],
        "/adlong": ["store-brands-bid"],
        "/anaze": ["store-brands-bid"],
        "/aoucosmetics": ["store-brands-bid"],
        "/flik": ["store-brands-bid"],
        "/glow": ["store-brands-bid"],
        "/haua": ["store-brands-bid"],
        "/palette/single": ["palette-single", "components-modal-EditPostModal"],
        "/palette/new": ["palette-new-index", "modal-CropModal"],
        "/palette/[pid]": ["palette-pid-index", "components-modal-FeedOptionModal", "components-modal-OptionSelectorPouchAddedModal", "components-modal-EditPostModal"],
        "/palette/[pid]/edit": ["palette-new-index", "components-modal-EditPostModal"],
        "/palette/creator/[ccode]": ["palette-creator-ccode", "components-modal-EditPostModal"],
        "/s/[ccode]": ["palette-creator-ccode", "mypage", "components-cards-MyInfoCard","components-pages-ProductDetail", "components-modal-EditPostModal"],
        "/s/[ccode]/[pid]": [
            "store-products-index", "components-buttons-PurchaseButton", "components-sections-product-ProductInfoSection",
            "components-input-OptionSelector", "components-modal-OptionSelectorPouchAddedModal", "components-pages-ProductDetail",
            "component-review-modal", "components-sections-product-ProductReviewSection", "components-buttons-ReviewScrapButton",
            "components-buttons-SubscriptionButton", "components-cards-FAQCard",
            "product-ProductInquirySection", 'modal-ProductInquiryModal', "components-modal-ProductDetailCouponDownloadModal",
            "components-cards-coupon-DownloadableCouponCard"
        ],
        "/reservation/products/[pid]": [
            "store-products-index", "components-buttons-PurchaseButton", "components-sections-product-ProductInfoSection",
            "components-input-OptionSelector", "components-modal-OptionSelectorPouchAddedModal", "components-pages-ProductDetail",
            "component-review-modal", "components-sections-product-ProductReviewSection", "components-buttons-ReviewScrapButton",
            "store-products-index", "components-buttons-SubscriptionButton", "components-cards-FAQCard", "reservation-products-pid",
            "modal-ProductInquiryModal", "product-ProductInquirySection", "components-modal-ProductDetailCouponDownloadModal",
            "components-cards-coupon-DownloadableCouponCard"
        ],
        "/reservation/products": ["reservation-products-index"],
        "/events/photo-share": ["events-photo-share"],
        "/events/sellershop-guide": ["events-sellershop-guide"],
        "/events/[eid]": [
            "events-eid-index", "components-layout-NavBar-StoreNavBar", "components-input-OptionSelector"
        ],
        "/events/[eid]/apply": [
            "events-eid-apply", "components-modal-DeliveryAddressModal", "components-cards-CheckoutAddressCard"
        ],
        "/events/[eid]/done": ["events-eid-done"],
        "/gift/[giftNum]": ["gift-giftNum-index"],
        "/gift/[giftNum]/detail": [
            "gift-giftNum-detail", "components-pages-complete", "components-pages-accept",
            "dropdown-OptionSelectionDropdownWithQuantitySelector", "components-input-OptionSelector",
            "components-modal-DeliveryAddressModal"
        ],
        "/store/orders/[ordNum]/checkout": [
            "component-new-checkout-option-card",
            "component-orders-checkout-price-section",
            "component-checkout-order-product-section",
            "components-modal-DeliveryAddressModal",
            "components-cards-CheckoutPointCard",
            "components-cards-CheckoutAddressCard",
            "store-orders-ordNum-checkout",
            "sections-checkout-CheckoutCouponInfoSection",
            "sections-checkout-CheckoutInfo",
            "components-cards-CheckoutCouponCard",
            "components-cards-coupon-DownloadableCouponCardV2",
            "components-cards-coupon-DownloadableCouponCard"
        ],
        "/store/orders/[ordNum]/success": [
            "component-new-checkout-option-card",
            "store-orders-ordNum-success",
        ],
        "/store/global-orders/[ordNum]/success": [
            "component-new-checkout-option-card",
            "store-orders-ordNum-success",
        ],
        '/privacy-policy': ['privacy-policy'],
        '/refund-policy': ['refund-policy'],
        '/shipping-policy': ['shipping-policy'],
        "/terms-of-service": ['components-terms-of-service']
    }
};

module.exports = moduleExports;
