
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import { useRouter } from "next/router";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import authStyles from "pages/auth/index.module.scss";
import utilStyles from "styles/utils.module.scss";
import TitleBar from "components/layout/TitleBar";
import Link from "next/link";
import { loginRoute, signUpCompleteRoute, storeRoute } from "common/const";
import FullButton from "components/buttons/FullButton";
import classNames from "classnames";
import TextInput from "components/input/TextInput";
import InputLabel from "components/input/InputLabel";
import CheckboxIcon from 'images/icons/checkbox_checked.svg';
import UncheckedCheckboxIcon from 'images/icons/checkbox_unchecked.svg';
import SignUpAgreement, { KEY_AGE, KEY_MARKETING_AGREED, KEY_PRIVACY_POLICY, KEY_TERMS_OF_SERVICE } from "components/input/SignUpAgreement";
import Modal from "components/Modal";
import { useContext, useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import InputError from "components/input/InputError";
import { Axios } from "api";
import UserContext from "context/AuthContext";
import CheckedInputError from "components/input/CheckedInputError";
import AuthLayout from "components/layout/AuthLayout";
import { captureException } from "@sentry/nextjs";
import { LOCAL_STORAGE_KEY_LAST_LOGIN_TYPE } from "pages/auth/sign-in";
import { REDIRECT_URI } from "components/buttons/NaverLoginButton";
import useTranslation from "next-translate/useTranslation";
export const KEY_CODE = 'code';
export const KEY_PASSWORD = 'password';
export const KEY_PASSWORD_CONFIRM = 'password_confirm';
export const KEY_NICKNAME = 'nickname';
export const KEY_EMAIL = 'email';
export const KEY_PASSWORD_CONTAINS_ALPHABET = 'password_contains_alphabet';
export const KEY_PASSWORD_CONTAINS_NUMBER = 'password_contains_number';
export const KEY_PASSWORD_CONTAINS_SPECIAL = 'password_contains_special';
export const KEY_NICKNAME_CONTAINS_ALPHABET = 'nickname_contains_alphabet';
export const KEY_NICKNAME_LEGAL = 'nickname_legal';
const KEY_EMAIL_EXISTS = 'email_exists';
export const KEY_NICKNAME_EXISTS = 'nickname_exists';
export const REGEX_CONTAINS_ALPHABET = /^.*[a-zA-Z].*$/;
export const REGEX_CONTAINS_DIGIT = /^.*\d.*$/;
export const REGEX_CONTAINS_SPECIAL = /^.*[^a-zA-Z\d].*$/;
export const REGEX_NICKNAME_LEGAL = /^[a-z\d._]*$/;
export const PASSWORD_ERROR_FIELDS = [KEY_PASSWORD_CONTAINS_SPECIAL, KEY_PASSWORD_CONTAINS_ALPHABET, KEY_PASSWORD_CONTAINS_NUMBER, KEY_PASSWORD];
export const NICKNAME_ERROR_FIELDS = [KEY_NICKNAME_CONTAINS_ALPHABET, KEY_NICKNAME_LEGAL, KEY_NICKNAME];
const SignUp = (props) => {
    const router = useRouter();
    const isMobile = useMediaQuery(`(max-width:${utilStyles.breakpointMobile})`);
    const userContext = useContext(UserContext);
    const [isAgreeModalOpen, setIsAgreeModalOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const { t } = useTranslation('auth');
    let redirectUrl = null;
    if (typeof window !== 'undefined') {
        const possibleRedirectUrl = decodeURIComponent((new URLSearchParams(window.location.search)).get('redirect') || window.localStorage.getItem(REDIRECT_URI));
        if (possibleRedirectUrl && possibleRedirectUrl !== 'null' && possibleRedirectUrl !== 'undefined') {
            redirectUrl = possibleRedirectUrl;
        }
    }
    const formik = useFormik({
        enableReinitialize: false,
        initialValues: {
            [KEY_NICKNAME]: '',
            [KEY_PASSWORD]: '',
            [KEY_PASSWORD_CONFIRM]: '',
            [KEY_EMAIL]: '',
            [KEY_AGE]: false,
            [KEY_TERMS_OF_SERVICE]: false,
            [KEY_PRIVACY_POLICY]: false,
            [KEY_MARKETING_AGREED]: false,
            [KEY_EMAIL_EXISTS]: '',
            [KEY_NICKNAME_EXISTS]: ''
        },
        validationSchema: yup.object({
            [KEY_PASSWORD]: yup.string().required().min(8).max(16),
            [KEY_PASSWORD_CONFIRM]: yup.string().when(KEY_PASSWORD, (password, schema) => {
                return schema.oneOf([password]);
            }),
            [KEY_EMAIL]: yup.string().required(t('auth:sign-up.email.required')).email(t('auth:sign-up.email.invalid')),
            [KEY_NICKNAME]: yup.string().required().min(2).max(20),
            [KEY_AGE]: yup.bool().required().oneOf([true]),
            [KEY_TERMS_OF_SERVICE]: yup.bool().required().oneOf([true]),
            [KEY_PRIVACY_POLICY]: yup.bool().required().oneOf([true]),
            [KEY_MARKETING_AGREED]: yup.bool().required().oneOf([true, false]),
            [KEY_PASSWORD_CONTAINS_ALPHABET]: yup.boolean().when(KEY_PASSWORD, (password, schema) => {
                if (!password)
                    return schema.required();
                if (!password.match(REGEX_CONTAINS_ALPHABET)) {
                    return schema.oneOf([true]).required();
                }
            }),
            [KEY_PASSWORD_CONTAINS_NUMBER]: yup.boolean().when(KEY_PASSWORD, (password, schema) => {
                if (!password)
                    return schema.required();
                if (!password.match(REGEX_CONTAINS_DIGIT)) {
                    return schema.oneOf([true]).required();
                }
            }),
            [KEY_PASSWORD_CONTAINS_SPECIAL]: yup.boolean().when(KEY_PASSWORD, (password, schema) => {
                if (!password)
                    return schema.required();
                if (!password.match(REGEX_CONTAINS_SPECIAL)) {
                    return schema.oneOf([true]).required();
                }
            }),
            [KEY_NICKNAME_CONTAINS_ALPHABET]: yup.boolean().when(KEY_NICKNAME, (nickname, schema) => {
                if (!nickname)
                    return schema.required();
                if (!nickname.match(REGEX_CONTAINS_ALPHABET)) {
                    return schema.oneOf([true]).required();
                }
            }),
            [KEY_NICKNAME_LEGAL]: yup.boolean().when(KEY_NICKNAME, (nickname, schema) => {
                if (!nickname)
                    return schema;
                if (!nickname.match(REGEX_NICKNAME_LEGAL)) {
                    return schema.oneOf([true]).required();
                }
            })
        }),
        onSubmit: async (values) => {
            if (values[KEY_NICKNAME_EXISTS]) {
                setIsAgreeModalOpen(false);
                alert(t('auth:sign-up.nicknameExists'));
                return;
            }
            if (values[KEY_EMAIL_EXISTS]) {
                setIsAgreeModalOpen(false);
                alert(t('auth:sign-up.emailExists'));
                return;
            }
            const data = JSON.parse(JSON.stringify(values));
            delete data[KEY_EMAIL_EXISTS];
            delete data[KEY_NICKNAME_EXISTS];
            setIsLoading(true);
            try {
                const res = await Axios.post('v1/auth/sign-up/', data);
                console.log(res);
                if (res.status < 400) {
                    const user = res.data;
                    try {
                        window.localStorage.setItem(LOCAL_STORAGE_KEY_LAST_LOGIN_TYPE, user.auth_type);
                    }
                    catch {
                    }
                    userContext.setUser(user);
                    if (redirectUrl) {
                        router.replace(redirectUrl);
                    }
                    else {
                        router.replace(signUpCompleteRoute);
                    }
                }
                else {
                    const err = res.data;
                    let err_message;
                    if (err.display_message) {
                        err_message = err.display_message;
                    }
                    else {
                        err_message = t('sign-up.error');
                    }
                    setIsAgreeModalOpen(false);
                    alert(err_message);
                }
            }
            catch (e) {
                setIsAgreeModalOpen(false);
                alert(t('sign-up.error'));
            }
            finally {
                setIsLoading(false);
            }
        }
    });
    const { errors, values, setFieldValue, touched, setFieldTouched, handleSubmit, setTouched, setFieldError } = formik;
    const fieldsExceptAgreement = [
        KEY_EMAIL, KEY_PASSWORD_CONTAINS_SPECIAL, KEY_PASSWORD_CONTAINS_ALPHABET, KEY_PASSWORD_CONTAINS_NUMBER, KEY_PASSWORD,
        KEY_PASSWORD_CONFIRM, KEY_NICKNAME, KEY_EMAIL_EXISTS, KEY_NICKNAME_EXISTS, KEY_NICKNAME_LEGAL, KEY_NICKNAME_CONTAINS_ALPHABET,
    ];
    const isMobileModalOpenable = values[KEY_NICKNAME] && Object.keys(errors).filter(key => fieldsExceptAgreement.includes(key)).length === 0;
    const isMobileSubmitButtonActive = values[KEY_NICKNAME] && values[KEY_PASSWORD] && values[KEY_PASSWORD_CONFIRM] && values[KEY_EMAIL];
    const openModal = () => {
        if (isMobileModalOpenable) {
            setIsAgreeModalOpen(true);
        }
    };
    const checkNickname = async (nickname) => {
        if (!nickname) {
            setFieldValue(KEY_NICKNAME_EXISTS, '');
        }
        try {
            const res = await Axios.get('v1/auth/check-nickname', { params: { nickname: nickname } });
            console.log(res);
            if (res.status === 409) {
                setFieldValue(KEY_NICKNAME_EXISTS, t('auth:sign-up.nicknameExists'));
            }
            else {
                setFieldValue(KEY_NICKNAME_EXISTS, '');
            }
        }
        catch (e) {
            captureException(e);
            setFieldValue(KEY_NICKNAME_EXISTS, '');
        }
    };
    const checkEmail = async (email) => {
        if (!email) {
            setFieldValue(KEY_EMAIL_EXISTS, '');
        }
        try {
            const res = await Axios.get('/v1/auth/check-email', { params: { email: email } });
            if (res.status === 409) {
                setFieldValue(KEY_EMAIL_EXISTS, t('auth:sign-up.emailExists'));
            }
            else {
                setFieldValue(KEY_EMAIL_EXISTS, '');
            }
        }
        catch (e) {
            captureException(e);
            setFieldValue(KEY_EMAIL_EXISTS, '');
        }
    };
    const onChangeNickname = (nickname) => {
        if (!nickname) {
            setFieldValue(KEY_NICKNAME, '');
        }
        const lowerNick = nickname.toLowerCase();
        if (lowerNick.match(REGEX_NICKNAME_LEGAL)) {
            setFieldValue(KEY_NICKNAME, lowerNick);
        }
    };
    const noError = (Object.values(errors).filter(e => !!e).length === 0 && !values[KEY_NICKNAME_EXISTS] && !values[KEY_EMAIL_EXISTS]) && !!values[KEY_NICKNAME];
    const nickNameError = !!values[KEY_NICKNAME_EXISTS] || (!!touched[KEY_NICKNAME] && NICKNAME_ERROR_FIELDS.filter(field => errors[field]).length > 0);
    return (<div className={classNames(authStyles.container, authStyles.fullHeightContainer, utilStyles.flexCol)}>
            {isMobile ?
            <TitleBar title={t('sign-up.title')} isBack close={() => router.back()}/>
            :
                <h1 className={utilStyles.mainTitle}>{t('sign-up.title')}</h1>}
            <div style={{ flexGrow: isMobile ? 1 : undefined }}>
                {isMobile && <div style={{ height: 24 }}/>}
                <InputLabel title={t('sign-up.email.label')}/>
                <TextInput readonly={isLoading} onBlur={() => setFieldTouched(KEY_EMAIL)} onChangeThrottled={checkEmail} error={values[KEY_EMAIL_EXISTS] || (!!touched[KEY_EMAIL] && !!errors[KEY_EMAIL])} value={values[KEY_EMAIL]} onChange={e => setFieldValue(KEY_EMAIL, e)} placeholder={t('sign-up.email.placeholder')} maxLength={254}/>
                {(values[KEY_EMAIL_EXISTS] || (errors[KEY_EMAIL] && touched[KEY_EMAIL])) &&
            <InputError message={errors[KEY_EMAIL] || values[KEY_EMAIL_EXISTS]}/>}
                <div style={{ height: 20 }}/>
                <InputLabel title={t('sign-up.password.label')}/>
                <TextInput readonly={isLoading} error={touched[KEY_PASSWORD] && PASSWORD_ERROR_FIELDS.filter(field => errors[field]).length > 0} onBlur={() => setFieldTouched(KEY_PASSWORD)} value={values[KEY_PASSWORD]} onChange={e => setFieldValue(KEY_PASSWORD, e)} type="password" placeholder={t('sign-up.password.placeholder')} maxLength={16}/>
                {(!!values[KEY_PASSWORD] || touched[KEY_PASSWORD]) &&
            <div style={{ marginTop: 6 }}>
                        <CheckedInputError isValid={!errors[KEY_PASSWORD_CONTAINS_ALPHABET]} message={t('auth:sign-up.password.containsAlphabet')}/>
                        <CheckedInputError isValid={!errors[KEY_PASSWORD_CONTAINS_NUMBER]} message={t('auth:sign-up.password.containsNumber')}/>
                        <CheckedInputError isValid={!errors[KEY_PASSWORD_CONTAINS_SPECIAL]} message={t('auth:sign-up.password.containsSpecialCharacter')}/>
                        <CheckedInputError isValid={!errors[KEY_PASSWORD]} message={t('auth:sign-up.password.length')}/>
                    </div>}
                <div style={{ height: 12 }}/>
                <TextInput readonly={isLoading} error={touched[KEY_PASSWORD_CONFIRM] && !!errors[KEY_PASSWORD_CONFIRM]} onBlur={() => setFieldTouched(KEY_PASSWORD_CONFIRM)} value={values[KEY_PASSWORD_CONFIRM]} onChange={e => setFieldValue(KEY_PASSWORD_CONFIRM, e)} type="password" placeholder={t('sign-up.password.confirmPlaceholder')} maxLength={16}/>
                {!!values[KEY_PASSWORD_CONFIRM] &&
            <div style={{ marginTop: 6 }}>
                        <CheckedInputError isValid={!errors[KEY_PASSWORD_CONFIRM]} message={t('auth:sign-up.password.confirm')}/>
                    </div>}
                <div style={{ height: 20 }}/>
                <InputLabel title={t('auth:sign-up.nickname.label')}/>
                <TextInput readonly={isLoading} error={nickNameError} value={values[KEY_NICKNAME]} onBlur={() => setFieldTouched(KEY_NICKNAME)} onChangeThrottled={checkNickname} onChange={onChangeNickname} placeholder={t('auth:sign-up.nickname.placeholder')} maxLength={20}/>
                {(!!values[KEY_NICKNAME] || touched[KEY_NICKNAME]) &&
            <div style={{ marginTop: 6 }}>
                        <CheckedInputError isValid={!errors[KEY_NICKNAME_CONTAINS_ALPHABET]} message={t('auth:sign-up.nickname.containsAlphabet')}/>
                        <CheckedInputError isValid={!errors[KEY_NICKNAME]} message={t('auth:sign-up.nickname.length')}/>
                        {values[KEY_NICKNAME_EXISTS] && <CheckedInputError isValid={false} message={t('auth:sign-up.nickname.exists')}/>}
                    </div>}
                {/*{(values[KEY_NICKNAME_EXISTS] || (errors[KEY_NICKNAME] && touched[KEY_NICKNAME])) && <InputError message={errors[KEY_NICKNAME] || values[KEY_NICKNAME_EXISTS]} />}*/}
            </div>
            {isMobile ?
            <Modal isOpen={isAgreeModalOpen} bottom width={'100%'} close={() => setIsAgreeModalOpen(false)}>
                        <div className={authStyles.agreeModalContainer}>
                            <SignUpAgreement formik={formik} disabled={isLoading}/>
                            <div style={{ height: 38 }}/>
                            <FullButton title={t('auth:sign-up.next')} height={48} fontSize={16} disabled={isLoading || !noError} onClick={handleSubmit}/>
                        </div>
                    </Modal>
            :
                <SignUpAgreement formik={formik} disabled={isLoading}/>}
            <a className={authStyles.stickyButtonContainer}>
                <FullButton title={t('auth:sign-up.button')} height={48} fontSize={16} disabled={isLoading || (isMobile ? (!isMobileSubmitButtonActive) : !noError)} onClick={isMobile ? openModal : handleSubmit}/>
            </a>
        </div>);
};
SignUp.getLayout = AuthLayout;
export default SignUp;

    async function __Next_Translate__getStaticProps__1949755057e__(ctx) {
      
      return {
        
        
        props: {
          
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/auth/sign-up',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__1949755057e__ as getStaticProps }
  